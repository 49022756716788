import React, { useContext, useEffect, useState } from "react";
import FlexBoxRow from "../flexBox/FlexBoxRow";
import { Text, TextBold } from "../text/Text";
import clockIcon from "../../assets/icons/clock.svg";
import { Context } from "../../context/Store";
import { useHistory } from "react-router";
import FlexBoxColumn from "../flexBox/FlexBoxColumn";
import { search } from "../../utils/Search";

const timeOutPopUp = (
  <FlexBoxColumn
    className="noscrollbar"
    alignItems="flex-start"
    mt="30px"
    fontSize="18px"
    overflow="scroll"
    width="100%"
  >
    <TextBold>Reservation utgick</TextBold>
    <Text>Var vänlig och gör om bokningen</Text>
  </FlexBoxColumn>
);

const Countdown = ({ endDate }) => {
  const [minutes, setMinutes] = useState("0");
  const [seconds, setSeconds] = useState("0");

  const { setPopUp, bookingTime, setCar, setReservation, setBookingAdditions } =
    useContext(Context);

  const pad = (number) => {
    return ("0" + number).slice(-2);
  };

  const history = useHistory();
  useEffect(() => {
    const msLeft = endDate - new Date();
    const minutesLeft = Math.max(Math.floor(msLeft / 60 / 1000), 0);
    setMinutes(pad(minutesLeft));
    const secondsLeft = Math.max(Math.floor((msLeft / 1000) % 60), 0);
    setSeconds(pad(secondsLeft));
    const timer = setInterval(() => {
      const msLeft = endDate - new Date();
      if (msLeft < 0) {
        setPopUp(timeOutPopUp);
        setCar(null);
        setReservation(null);
        setBookingAdditions(null);
        search(history, bookingTime);
      }

      const minutesLeft = Math.max(Math.floor(msLeft / 60 / 1000), 0);
      setMinutes(pad(minutesLeft));
      const secondsLeft = Math.max(Math.floor((msLeft / 1000) % 60), 0);
      setSeconds(pad(secondsLeft));
    }, 1000);
    return () => clearInterval(timer);
  }, [
    endDate,
    history,
    bookingTime,
    setPopUp,
    setCar,
    setReservation,
    setBookingAdditions,
  ]);

  return (
    <FlexBoxRow fontSize="20px">
      <TextBold>Bil reserverad:</TextBold>
      <Text mx="10px">
        {minutes}:{seconds}
      </Text>
      <img src={clockIcon} alt="" />
    </FlexBoxRow>
  );
};

export default Countdown;
