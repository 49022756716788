import { Box, makeStyles, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Context } from "../../context/Store";

import { calculatePrice } from "../../utils/price";
import FlexBoxColumn from "../flexBox/FlexBoxColumn";
import Picture from "../picture/Picture";
import StyledButton from "../styledButton/StyledButton";
import { TextBold } from "../text/Text";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
    alignContent: "center",
    paddingBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingBottom: "0",
    },
  },
  button: {
    "& .MuiButton-contained": {
      [theme.breakpoints.down("sm")]: {
        borderRadius: "0px 0px 4px 4px",
      },
    },
  },
  image: {
    width: "100%",
  },
  banner: {
    position: "absolute",
    height: "9%",
    backgroundColor: "red",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "-7%",
    zIndex: 1,
    transform: "rotate(30deg)",
    left: "24%",
    top: "5%",

    [theme.breakpoints.down("xs")]: {
      left: "-10%",
      top: "4%",
      width: "150%",
      height: "5%",
    },
  },
  bannerText: {
    color: "white",
    fontSize: "25px",

    [theme.breakpoints.down("xs")]: {
      fontSize: "15px",
    },
  },
}));

const CarCardSimple = ({ car, height }) => {
  const [price, setPrice] = useState(0);
  const [perTimePeriod, setPerTimePeriod] = useState("");

  const { modell, priceGroup } = car;

  const history = useHistory();
  const { setCar, priceLists } = useContext(Context);

  const selectCar = () => {
    setCar(car);

    history.push("/bokning/tid");
  };

  useEffect(() => {
    if (!priceLists) {
      return;
    }

    let startDate = new Date();
    let endDate = new Date(startDate);
    endDate.setHours(startDate.getHours() + 1);

    const distance = 0;

    let camp = undefined;
    if (car.campaign) {
      camp = car.campaign;
      let now = new dayjs();
      let end;
      if (camp.day) {
        setPerTimePeriod("per dag");

        end = now.add(1, "day");
      } else if (camp.week) {
        setPerTimePeriod("per vecka");

        end = now.add(1, "week");
      } else if (camp.month) {
        setPerTimePeriod("per månad");

        end = now.add(1, "month");
      }
      startDate = now.toDate();
      endDate = end.toDate();
    }

    calculatePrice(
      startDate,
      endDate,
      distance,
      priceLists,
      car.debitedCargroup,
      car.campaign
    )
      .then((price) => {
        if (camp) {
        } else if (price.base.type === "T04") {
          setPerTimePeriod("för 4 timmar");
        } else {
          setPerTimePeriod("per dag");
        }
        setPrice(price.total.customer);
      })
      .catch();
  }, [priceLists, priceGroup]);

  const classes = useStyles();
  return (
    <FlexBoxColumn
      className={classes.root}
      style={{ height: height ?? "auto", borderRadius: 5, overflow: "hidden" }}
      position="relative"
    >
      {car.campaign ? (
        <Box className={classes.banner}>
          <Typography className={classes.bannerText}>Kampanj</Typography>
        </Box>
      ) : (
        <></>
      )}

      <Picture
        name={car.name}
        carclass={car.carClass}
        width="100%"
        border="5px 5px 0px 0px"
      />
      <TextBold fontSize={["20px", "20px", "24px"]} my="10px">
        {modell}
      </TextBold>
      <TextBold color="#767676" mt="auto">
        från
      </TextBold>
      {car.campaign ? (
        <TextBold fontSize={["24px", "24px", "30px"]} color="#EC2B34">
          {price + " SEK"}
        </TextBold>
      ) : (
        <TextBold fontSize={["24px", "24px", "30px"]} color="#000000">
          {price + " SEK"}
        </TextBold>
      )}

      <TextBold color="#767676">{perTimePeriod}</TextBold>
      <Box
        width={["100%", "100%", "50%"]}
        mt={["25px", "25px", "35px"]}
        className={classes.button}
      >
        <StyledButton color="primary" onClick={selectCar}>
          <TextBold fontSize="18px">Välj</TextBold>
        </StyledButton>
      </Box>
    </FlexBoxColumn>
  );
};

export default CarCardSimple;
