import React from "react";
import { useState, useEffect } from "react";

const maxMobileWidth = 960;

function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addEventListener("change", listener);
    return () => media.removeEventListener("change", listener);
  }, [matches, query]);

  return matches;
}

export const useIsDesktop = () => {
  return useMediaQuery(`(min-width: ${maxMobileWidth + 1}px)`);
};

export const useIsMobile = () => {
  return useMediaQuery(`(max-width: ${maxMobileWidth}px)`);
};

export const Desktop = ({ children }) => {
  const isDesktop = useIsDesktop();
  return <>{isDesktop ? children : null}</>;
};
export const Mobile = ({ children }) => {
  const isMobile = useIsMobile();
  return isMobile ? children : null;
};
