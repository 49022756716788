import { Box, Container, Grid } from "@material-ui/core";
import { Text, TextBold } from "../../text/Text";
import StyledInput from "../../styledInput/StyledInput";
import StyledButton from "../../styledButton/StyledButton";
import { useEffect, useState } from "react";
import { functions } from "../../../database/firebase";
import PersonNumberInput from "../../personNumberInput/PersonNumberInput";
import Datepicker from "../../datePicker/Datepicker";

const InputField = ({ label, value, onChange }) => {
  return (
    <Grid item xs={12} sm={6}>
      <Text mb="10px">{label}</Text>
      <StyledInput value={value} onChange={onChange} bgcolor="#EEE" />
    </Grid>
  );
};

const RemoveBookingOld = () => {
  useEffect(() => {
    document.title = "Avboka";
  }, []);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [personNumber, setPersonNumber] = useState("");
  const [email, setEmail] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const toDate = (dateNr) => {
    const date = new Date(dateNr);
    const year = date.getYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();

    const addPadding = (number) => {
      return number <= 9 ? "0" + number.toString() : number.toString();
    };

    // Month number is 0-indexed
    let dateString =
      1900 + year + "-" + addPadding(month + 1) + "-" + addPadding(day);

    dateString += " " + addPadding(hour) + ":" + addPadding(minute);

    return dateString;
  };

  const submit = async () => {
    const cancelBooking = functions.httpsCallable("booking-cancelWithMail");
    if (loading) return;
    setLoading(true);
    setMessage("");
    if (firstName === "") {
      setMessage("Förnamn saknas");
    } else if (lastName === "") {
      setMessage("Efternamn saknas");
    } else if (personNumber === "" || personNumber.includes("_")) {
      setMessage("Ogiligt personnummer/organisationsnummer");
    } else if (email === "") {
      setMessage("E-post saknas");
    } else {
      try {
        await cancelBooking({
          firstName,
          lastName,
          personNumber,
          email,
          startDate: toDate(startDate),
          endDate: toDate(endDate),
        });
        setMessage("Bokning är nu avbokad");
      } catch (error) {
        setMessage(
          "Något gick fel, om felet kvarstår kontakta folkes på telefon"
        );
      }
    }
    setLoading(false);
  };
  return (
    <Container maxWidth="md">
      <TextBold
        component="h1"
        fontSize={["24px", "24px", "35px"]}
        mt="30px"
        mb="40px"
      >
        Avboka
      </TextBold>
      <Box
        bgcolor={"#FFF"}
        border={"1.25px solid #F2F2F2"}
        borderRadius={5}
        maxWidth="500px"
        mx="auto"
        mb={["60px", "90px"]}
        textAlign="left"
        p={["30px", "20px", "50px"]}
      >
        <Text fontSize="22px" my="5px">
          Bokning
        </Text>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Text my="10px">Från</Text>
            <Box bgcolor="#EEE">
              <Datepicker
                style={{ width: "100%" }}
                selected={startDate}
                handleSelect={(date) => setStartDate(date.value)}
                showTimeSelect
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Text my="10px">Till</Text>
            <Box bgcolor="#EEE">
              <Datepicker
                style={{ width: "100%" }}
                selected={endDate}
                handleSelect={(date) => setEndDate(date.value)}
                showTimeSelect
              />
            </Box>
          </Grid>
        </Grid>
        <Text fontSize="22px" my="10px">
          Dina uppgifter
        </Text>
        <Grid container spacing={2}>
          <InputField
            label="Förnamn:"
            value={firstName}
            onChange={setFirstName}
          />
          <InputField
            label="Efternamn:"
            value={lastName}
            onChange={setLastName}
          />

          <Grid item xs={12} sm={6}>
            <Text mb="10px">Personnummer</Text>
            <PersonNumberInput
              value={personNumber}
              onChange={setPersonNumber}
              bgcolor="#EEE"
            />
          </Grid>
          <InputField label="Email:" value={email} onChange={setEmail} />
          <Grid item xs={12} sm={12}>
            <StyledButton
              width="100%"
              loading={loading}
              type="submit"
              onClick={submit}
              fullWidth
            >
              Avboka
            </StyledButton>
          </Grid>
        </Grid>
        <Box my="30px"></Box>
        <Text fontSize="20px" my="10px" color="#E00">
          {message}
        </Text>

        <Text display="inline">Avboka på telefon? Ring folkes </Text>
        <Text
          display="inline"
          color="#3366bb"
          component="a"
          href="tel:+46-46-354140"
        >
          046-354140
        </Text>
      </Box>
    </Container>
  );
};

export default RemoveBookingOld;
