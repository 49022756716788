// Reserve a car and start booking process
import {getSearchParams} from "./Search";
import {functions} from "../database/firebase";

const beginBookingProcess = async (car, setMadeBooking, setReservation, setCar, queries, history) => {
  const { startDate, endDate } = getSearchParams(queries);
  const reserveCar = functions.httpsCallable("cars-reserve");

  try {
    const response = await reserveCar({
      startDate: startDate.getTime(),
      endDate: endDate.getTime(),
      cars: car.availableCars,
    }).then((res) => {
      return res.data;
    });

    if (response.available) {
      // Prevent useEffect from unreserving car
      setMadeBooking(true);
      setReservation({
        until: new Date(response.until),
        carId: response.carId,
      });
      setCar(car);
      history.push("/bokning/tillval");
    } else {
      console.log(response);
    }
  } catch {
    console.log("FUNKA INTE");
  }
};

export default beginBookingProcess;