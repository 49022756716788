export const constrictTime = (time, openHours) => {
  if (openHours) {
    let weekDay = openHours.weekDays.filter((weekDay) => {
      let dayNr = time.getDay();
      dayNr = dayNr === 0 ? 7 : dayNr;
      return weekDay.DAGNR === dayNr;
    });
    let lowerBound = new Date(time);
    let upperBound = new Date(time);

    lowerBound.setHours(weekDay[0].FRAN);
    lowerBound.setMinutes(0);

    upperBound.setHours(weekDay[0].TILL);
    upperBound.setMinutes(0);

    if (time < lowerBound) {
      return lowerBound;
    }
    if (time > upperBound) {
      return upperBound;
    }
  }
  return time;
};

export const changeStartDate = (
  date,
  openHours,
  endDate,
  setStartDate,
  setEndDate
) => {
  
  const startDate = constrictTime(date, openHours);
  const plus15min = new Date(startDate);
  plus15min.setMinutes(plus15min.getMinutes() + 15);
  if (plus15min >= endDate) {
    endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 1);
  }
  let newDates = toValidTimes(openHours, startDate, endDate);
  setStartDate(newDates.startDate);
  setEndDate(newDates.endDate);
};

export const changeEndDate = (date, startDate, setEndDate) => {
  if (startDate >= date) {
    const nextDate = new Date(startDate);
    nextDate.setMinutes(nextDate.getMinutes() + 30);
    setEndDate(nextDate);
  } else {
    setEndDate(date);
  }
};

export const toValidTimes = (openHours, startDate, endDate) => {
  let firstDate = startDate;
  let lastDate = endDate;
  // Make sure startDate is after now
  if (firstDate <= new Date()) {
    let hourAfterNow = new Date();
    hourAfterNow.setHours(hourAfterNow.getHours() + 1);
    firstDate = hourAfterNow;
  }
  // Make sure that startDate is within open times
  if (openHours) {
    if (
      !isOpenDateFunc(openHours)(firstDate) ||
      !isOpenTimeFunc(openHours)(firstDate)
    ) {
      firstDate = getNextOpenTime(firstDate, openHours);
    }
  }

  // Make sure endDate is an hour after startDate
  let hourBeforeEndDate = new Date(lastDate);
  hourBeforeEndDate.setHours(hourBeforeEndDate.getHours() - 1);
  if (firstDate >= hourBeforeEndDate) {
    let hourAfterStartDate = new Date(firstDate);
    hourAfterStartDate.setHours(hourAfterStartDate.getHours() + 1);
    lastDate = hourAfterStartDate;
  }
  return { startDate: firstDate, endDate: lastDate };
};

// Check if its open at the time
export const isOpenTimeFunc = (openHours) => {
  return (time) => {
    if (time < new Date()) {
      return false;
    } 
    if (openHours) {
      const hourInFuture = new Date();
      hourInFuture.setHours(hourInFuture.getHours() + 1);

      let holiday = openHours.redDays.filter(
        (holiday) => holiday.DATUM === time.toDateString()
      );

      if (holiday[0]) {
        const holidayEndDate = new Date(holiday[0].TILL);
        holidayEndDate.setMinutes(holidayEndDate.getMinutes() - 15);
        return (
          new Date(holiday[0].FRAN) <= time &&
          time <= holidayEndDate &&
          hourInFuture <= new Date(holiday[0].TILL)
        );
      }

      let weekDay = openHours.weekDays.filter((weekDay) => {
        let dayNr = time.getDay();
        dayNr = dayNr === 0 ? 7 : dayNr;
        return weekDay.DAGNR === dayNr;
      });

      let lowerBound = new Date(time);
      let upperBound = new Date(time);

      lowerBound.setHours(weekDay[0].FRAN);
      lowerBound.setMinutes(0);

      // Close 15 minutes early
      upperBound.setHours(weekDay[0].TILL);
      upperBound.setMinutes(-15);

      return (
        lowerBound <= time && time <= upperBound && hourInFuture <= upperBound
      );
    } else {
      return true;
    }
  };
};

// Check if the its open on the date
export const isOpenDateFunc = (openHours) => {
  return (date) => {
    const now = new Date();
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    if (date < today) {
      return false;
    }
    if (openHours) {
      let holiday = openHours.redDays.filter(
        // TODO - change string comparison to something more robust
        (holiday) => holiday.DATUM === date.toDateString()
      );
      if (holiday[0]) {
        if (holiday[0].STANGT) {
          return true;
        }
        const holidayEndDate = new Date(holiday[0].TILL);
        holidayEndDate.setMinutes(holidayEndDate.getMinutes() - 15);
        return now <= holidayEndDate;
      } else {
        let weekDay = openHours.weekDays.filter((weekDay) => {
          let dayNr = date.getDay();
          dayNr = dayNr === 0 ? 7 : dayNr;
          return weekDay.DAGNR === dayNr;
        });

        let upperBound = new Date(date);

        // Close 15 minutes early
        upperBound.setHours(weekDay[0].TILL);
        upperBound.setMinutes(-15);

        return now <= upperBound;
      }
    }
    return true;
  };
};

// Gets the earliest open time atleast from tomorrow
export const getNextOpenTime = (date, openHours) => {
  let newDate = date;
  newDate.setDate(newDate.getDate());
  newDate.setHours(12);
  newDate.setMinutes(0);
  if (openHours) {
    const isOpenNow =
      isOpenDateFunc(openHours)(new Date()) &&
      isOpenTimeFunc(openHours)(new Date());

    for (let i = 0; i < 10; i++) {
      if (isOpenDateFunc(openHours)(newDate)) {
        let weekDay = openHours.weekDays.filter((weekDay) => {
          let dayNr = newDate.getDay();
          dayNr = dayNr === 0 ? 7 : dayNr;
          return weekDay.DAGNR === dayNr;
        });

        let lowerBound = new Date(newDate);

        lowerBound.setHours(weekDay[0].FRAN);
        let upperBound = new Date(newDate);
        upperBound.setHours(weekDay[0].TILL);
        // If its closed now then stops customer from booking first hour morning after
        
        let openTime = isOpenTimeFunc(openHours)(lowerBound);
        for(let k = 0; !openTime && k < 15; k++) {
          console.log("Open now:",openTime);
          lowerBound.setHours(lowerBound.getHours() + 1);
          openTime = isOpenTimeFunc(openHours)(lowerBound);
        }
        
        if (!isOpenNow) {
          lowerBound.setHours(lowerBound.getHours() + 1);
        }
        lowerBound.setMinutes(0);

        
        if(lowerBound.getHours() < upperBound.getHours()) {
          return lowerBound;
        }
      }
      newDate.setDate(newDate.getDate() + 1);
    }
    return newDate;
  } else {
    return newDate;
  }
};
