import { Box, Container, Grid } from "@material-ui/core";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { functions } from "../../../database/firebase";
import CarCardInfo from "../../carCardInfo/CarCardInfo";
import Search from "../../search/Search";
import { Text, TextBold } from "../../text/Text";
import { ReactComponent as LoadingIcon } from "../../../assets/icons/loading.svg";
import { Context } from "../../../context/Store";
import { getSearchParams } from "../../../utils/Search";
import { useHistory } from "react-router-dom";
import { toValidTimes } from "../../../utils/pickUpTime";
import NoticeCard from "../../noticeCard/NoticeCard";
import beginBookingProcess from "../../../utils/beginBookingProcess";
import SearchMini from "../../search/SearchMini";

/**
 * Search results site
 * @author Erik Jonasson
 */
const SearchResults = () => {
  useEffect(() => {
    document.title = "Sökresultat";
  }, []);

  const history = useHistory();
  const { queries } = useParams();
  const {
    setCar,
    setReservation,
    reservation,
    bookingTime,
    setBookingTime,
    openHours,
  } = useContext(Context);

  const [isLoadingCars, setIsLoadingCars] = useState(false);
  // List of carstypes with list of available carDocIds
  const [carList, setCarList] = useState([]);
  const [madeBooking, setMadeBooking] = useState(false);
  const [carLoadingPage, setCarLoadingPage] = useState(null);

  // Used to give the carCard the distance for priceCalculation
  const [localBookingTime, setLocalBookingTime] = useState(bookingTime);

  // Check if carList has any available cars
  const isCarsAvailable = useMemo(() => {
    for (const car of carList) {
      if (car.availableCars.length > 0) {
        return true;
      }
    }
    return false;
  }, [carList]);

  // Reserve a car and start booking process
  const selectCar = async (car) => {
    if (carLoadingPage) {
      return;
    }
    setCarLoadingPage(car.modell);
    setBookingTime(localBookingTime);
    await beginBookingProcess(
      car,
      setMadeBooking,
      setReservation,
      setCar,
      queries,
      history
    );
    setCarLoadingPage(null);
  };

  const changeBookingTime = (car) => {
    window.scrollTo(0, 0);
  };

  const distanceChanged = async (distance) => {
    setLocalBookingTime((prev) => ({ ...prev, distance: distance }));
  };

  // Unreserve car
  useEffect(() => {
    if (!madeBooking && reservation) {
      const resetReservation = functions.httpsCallable("cars-unreserve");
      try {
        resetReservation({ carId: reservation.carId });
        setReservation(null);
      } catch (error) {
        console.log(error);
      }
    }
  }, [reservation, setReservation, madeBooking]);

  // Updates the searchbar and fetches cars
  useEffect(() => {
    // If openHours is not available, skip running the effect
    // openHours will be updated from null after first render, which triggers this effect again
    if (!openHours) return

    let { carClass, transmission, startDate, endDate, distance, towbar } =
      getSearchParams(queries);

    let newDates = toValidTimes(openHours, startDate, endDate);
    startDate = newDates.startDate;
    endDate = newDates.endDate;

    setLocalBookingTime({
      startDate,
      endDate,
      distance,
      carClass,
      transmission,
      towbar,
    });

    setBookingTime({
      startDate,
      endDate,
      distance,
      carClass,
      transmission,
      towbar,
    });

    const getAvailableCars = functions.httpsCallable("cars-getAvailable");
    if (!isNaN(startDate) && !isNaN(endDate)) {
      (async () => {
        setIsLoadingCars(true);
        try {
          const cars = await getAvailableCars({
            startDate: Number(startDate),
            endDate: Number(endDate),
            carClass: carClass,
            transmission: transmission,
            towbar: towbar,
          }).then((res) => {
            return JSON.parse(res.data);
          });
          cars.sort((a, b) => {
            const aCars = a.availableCars.length;
            const bCars = b.availableCars.length;

            if (Math.min(aCars, 1) !== Math.min(bCars, 1)) {
              return bCars - aCars;
            }

            return a.priceGroup.localeCompare(b.priceGroup);
          });
          setCarList(cars);
        } catch (error) {
          console.log(error);
          setCarList([]);
        }
        setIsLoadingCars(false);
      })();
    }
  }, [queries, setBookingTime, openHours, setLocalBookingTime]);

  return (
    <>
      <Container maxWidth="md">
        <TextBold
          component="h1"
          fontSize={["24px", "24px", "35px"]}
          mt="30px"
          mb="40px"
          textAlign={"left"}
        >
          Sökresultat för:
        </TextBold>
      </Container>
      <Search
        bgcolor={["transparent", "transparent", "#FFF"]}
        color={"#000"}
        inputBgcolor={["#FFF", "#FFF", "#EEE"]}
        altView
        distanceChanged={distanceChanged}
      />
      <Container maxWidth="md">
        <NoticeCard carClass={bookingTime?.carClass} />
        <Box
          my={2}
          borderTop="2px solid #EEE"
          mt="30px"
          pt="30px"
          mb={["60px", "90px"]}
        >
          {isLoadingCars ? (
            <LoadingIcon width="150px" height="150px" fill="#E0E0E0" />
          ) : (
            <Grid container spacing={2}>
              {!isCarsAvailable && (
                <Box width="100%">
                  <TextBold fontSize={["24px", "30px"]}>
                    Din sökning gav inget resultat
                  </TextBold>

                  <Text fontSize={["16px", "20px"]} display="inline">
                    Det finns tyvärr inga lediga fordon den önskade perioden.
                    Försök välja en annan tid, alternativt kontakta oss på{" "}
                  </Text>
                  <Text
                    fontSize={["16px", "20px"]}
                    display="inline"
                    color="#3366bb"
                    component="a"
                    href="mailto:info@folkesbiluhyrning.se"
                  >
                    info@folkesbiluhyrning.se{" "}
                  </Text>
                  <Text fontSize={["16px", "20px"]} display="inline">
                    för mer information.
                  </Text>
                </Box>
              )}
              {carList.map((car) => {
                const available = car.availableCars.length > 0;
                return (
                  <Grid item key={car.modell} xs={12} sm={6} md={12}>
                    <CarCardInfo
                      car={car}
                      notAvailable={car.availableCars.length === 0}
                      changeBooking={car.availableCars.length === 0}
                      bookingTime={localBookingTime}
                      onSelect={available ? selectCar : changeBookingTime}
                      buttonText={available ? undefined : "Sök nytt datum"}
                      opacity={available ? 1 : 0.7}
                      loading={carLoadingPage === car.modell}
                      height="100%"
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Box>
      </Container>
    </>
  );
};
export default SearchResults;
