import FlexBoxColumn from "../../flexBox/FlexBoxColumn";
import { Text, TextBold } from "../../text/Text";
import damageCostImg from "../../../assets/images/skade_kostnad.svg";
import rentingPdf from "../../../assets/pdfs/hyresvillkor.pdf";

const DamageReductionInfo = (
  <FlexBoxColumn
    className="noscrollbar"
    mt="30px"
    alignItems="flex-start"
    fontSize="18px"
    overflow="scroll"
    width="100%"
  >
    <TextBold fontSize="22px" width="100%">
      Skadekostnadsreducering
    </TextBold>
    <img
      alt=""
      src={damageCostImg}
      style={{ maxWidth: "700px", width: "100%", margin: "15px 0px" }}
    />
    <Text width="100%" className="wordBreak">
      Hyresmannen kan mot särskild avgift
      (skadekostnadsreducering/självriskreducering), vars storlek framgår enligt
      prislista, minska sin ersättningsskyldighet för varje skademoment.
      <br />
      Efter sådan reducering begränsas hyresmannens ersättningsskyldighet för
      skador till 2500-10750kr/marknadsvärde beroende på skademoment.
      <br />
      <br />
      Ersättningsreducering gäller inte om hyresmannen utan uthyrarens
      skriftliga godkännande brukar bilen utanför Sveriges gränser.
      <br />
      <br />
      Ersättningsreduceringen omfattar inte den ersättningsskyldighet som
      debiteras om fordonet stjäls med nyckel.
      <br />
      <br />
      För att ersättningsreduceringen skall gälla måste hyresmannen uppfylla
      ordinarie skyldigheter vid skadefall, t.ex. omedelbart lämna skadeanmälan
      till uthyraren. Utfallande försäkringsersättning tillkommer uthyraren om
      försäkring avser dennes intresse och annars hyresmannen.
      <br />
      <br />
    </Text>
    <a href={rentingPdf} rel="noopener noreferrer" target="_blank">
      <Text fontSize="18px" color="#c00" lineHeight="1.2">
        Fullständiga hyresvillkor
      </Text>
    </a>
  </FlexBoxColumn>
);
export default DamageReductionInfo;