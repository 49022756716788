import { Box } from "@material-ui/core";
import React from "react";

/**
 * Text field for easy responsive styling
 * @author Erik Jonasson
 */
const Text = (props) => {
  return (
    <Box
      fontFamily="Replica"
      fontSize={props.fontSize ?? "inherit"}
      lineHeight={props.lineHeight ?? "inherit"}
      textAlign={props.textAlign ?? "inherit"}
      component={props.component ?? "p"}
      color={props.color ?? "inherit"}
      {...props}
    ></Box>
  );
};

/**
 * Bold text field for easy responsive styling
 * @author Erik Jonasson
 */
const TextBold = (props) => {
  return (
    <Box
      fontFamily="ReplicaBold"
      fontSize={props.fontSize ?? "inherit"}
      lineHeight={props.lineHeight ?? "inherit"}
      textAlign={props.textAlign ?? "inherit"}
      component={props.component ?? "p"}
      color={props.color ?? "inherit"}
      {...props}
    ></Box>
  );
};

export { Text, TextBold };
