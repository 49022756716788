import React from "react";
import "./App.scss";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { createMuiTheme, makeStyles, ThemeProvider } from "@material-ui/core";
import OurCars from "./components/pages/ourCars/OurCars";
import Home from "./components/pages/home/Home";
import SearchResults from "./components/pages/searchResults/SearchResults";
import BookingAdditions from "./components/pages/bookingAdditions/BookingAdditions";
import BookingFinish from "./components/pages/bookingFinish/BookingFinish";
import BookingTime from "./components/pages/bookingTime/BookingTime";
import Store from "./context/Store";
import BookingConfirmation from "./components/pages/bookingConfirmation/BookingConfirmation";
import AboutUs from "./components/pages/aboutUs/AboutUs";
import FAQ from "./components/pages/FAQ/FAQ";
import OpeningHours from "./components/pages/openingHours/OpeningHours";
import Login from "./components/pages/login/Login";
import Register from "./components/pages/register/Register";
import PopUp from "./components/popUp/PopUp";
import Profile from "./components/pages/profile/Profile";
import UpdateProfileInfo from "./components/pages/updateProfileInfo/UpdateProfileInfo";
import RemoveBooking from "./components/pages/removeBooking/RemoveBooking";
import RemoveBookingOld from "./components/pages/removeBooking/RemoveBookingOld";
import CookieBanner from "./components/cookieBanner/CookieBanner";
import ScrollReset from "./components/scrollReset/ScrollReset";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#EC2B34",
    },
    secondary: {
      main: "#F2F2F2",
    },
    background: {
      default: "#F9F9F9",
    },
  },
  typography: {
    fontFamily: "ReplicaBold, Arial",
  },
});

theme.typography.h1 = {
  fontSize: "35px",
  [theme.breakpoints.down("md")]: {
    fontSize: "24px",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiContainer-root": {
      paddingLeft: "26px",
      paddingRight: "26px",
    },
  },
}));

function App() {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Store>
        <PopUp />
        <Router>
          <ScrollReset>
            <div className={`App ${classes.root}`}>
              <Header />
              <Switch>
                <Route component={Home} exact path={"/"} />
                <Route component={OurCars} exact path={"/vara-fordon"} />
                <Route component={AboutUs} exact path={"/om-oss"} />
                <Route component={FAQ} exact path={"/faq"} />
                <Route component={OpeningHours} exact path={"/oppetider"} />
                <Route component={SearchResults} exact path={"/sok/:queries"} />
                <Route component={SearchResults} exact path={"/sok"} />
                <Route component={RemoveBookingOld} exact path={"/avboka"} />
                <Route component={BookingTime} exact path={"/bokning/tid"} />
                <Route
                  component={BookingFinish}
                  exact
                  path={"/bokning/slutfor"}
                />
                <Route
                  component={BookingConfirmation}
                  exact
                  path={"/bokning/konfirmation"}
                />
                <Route
                  component={BookingAdditions}
                  exact
                  path={"/bokning/tillval"}
                />
                <Route component={Login} exact path={"/profil/logga-in"} />
                <Route component={Register} exact path={"/profil/registrera"} />
                <Route component={Profile} exact path={"/profil/mina-sidor"} />
                <Route
                  component={BookingAdditions}
                  exact
                  path={"/profil/andra-bokning"}
                />
                <Route
                  component={UpdateProfileInfo}
                  exact
                  path={"/profil/andra-uppgifter"}
                />
                <Route path="/">
                  <Redirect to="/" />
                </Route>
              </Switch>
              <Footer />
              <div id="calendar-portal"></div>
              <CookieBanner />
            </div>
          </ScrollReset>
        </Router>
      </Store>
    </ThemeProvider>
  );
}

export default App;
