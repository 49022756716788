import { Box } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";

import carIcon from "../../assets/icons/carTypes/car.svg";
import vanIcon from "../../assets/icons/carTypes/van.svg";
import truckIcon from "../../assets/icons/carTypes/truck.svg";
import trailerIcon from "../../assets/icons/carTypes/trailer.svg";
import { storage } from "../../database/firebase";

/**
 * Used to display images while keep its aspect ratio
 * @author Erik Jonasson
 */
const Picture = (props) => {
  const { name, carclass } = props;

  const [carImage, setCarImage] = useState(null);

  const defaultIcon = useMemo(() => {
    if (carclass === "Personbil") return carIcon;
    else if (carclass === "Buss") return vanIcon;
    else if (carclass === "Lastbil") return truckIcon;
    else if (carclass === "Släp") return trailerIcon;
    else return carIcon;
  }, [carclass]);

  // Gets the image of the car
  useEffect(() => {
    // If image not found defaultIcon will be show instead
    storage
      .ref()
      .child(`biltyp/${name}.jpg`)
      .getDownloadURL()
      .then((url) => setCarImage(url));
  }, [name]);

  return (
    <Box {...props}>
      <Box
        width="100%"
        pt="62.5%"
        position="relative"
        borderRadius={props.border}
      >
        <img
          src={carImage ?? defaultIcon}
          alt=""
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: "cover", 
            // objectPosition: "center", 
            // paddingTop: "75%", 
            width: "100%",
            height: "100%",
            borderRadius: "inherit",
          }}
        ></img>
      </Box>
    </Box>
  );
};

export default Picture;
