import {
  Grid,
  Button,
  makeStyles,
  Hidden,
  Box,
  useMediaQuery,
  useTheme, Container, Collapse, Slide, Fade,
} from "@material-ui/core";
import React, {useContext, useEffect, useRef, useState} from "react";
import SearchIcon from "@material-ui/icons/Search";
import Datepicker from "../datePicker/Datepicker";
import {useHistory} from "react-router-dom";
import {search} from "../../utils/Search";
import {TextBold} from "../text/Text";
import Dropdown from "../dropdown/Dropdown";
import CarPicker from "../carPicker/CarPicker";
import StyledInput from "../styledInput/StyledInput";
import {Context} from "../../context/Store";
import FlexBoxRow from "../flexBox/FlexBoxRow";
import {
  changeEndDate,
  changeStartDate,
  getNextOpenTime,
  isOpenDateFunc,
  isOpenTimeFunc,
} from "../../utils/pickUpTime";
import FlexBoxColumn from "../flexBox/FlexBoxColumn";
import FormatListBulleted from "@material-ui/icons/FormatListBulleted";
import {transmissionTypes} from "../../types";
import SearchMini from "./SearchMini";
import Moment from "moment";
import StyledCheckbox from "../styledCheckbox/StyledCheckbox";
import towbarIcon from "../../assets/icons/cardIcons/car_type_black.svg";
import automaticIcon from "../../assets/icons/cardIcons/automatic.svg";
import transmissionIconBlack from "../../assets/icons/cardIcons/transmissionBlack.svg";

const useStyles = makeStyles((theme) => ({
  button: {
    height: "44px",
    width: "100%",
    boxShadow: "none",
    borderRadius: "5px",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      height: "43px",
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
      width: "100%",
    },
  },
  buttonWrapper: {
    //height: "100%",
    //marginTop: "24px",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
}));

const InputLabel = ({children, height}) => {
  return (
    <FlexBoxColumn
      alignItems="flex-start"
      justifyContent="flex-end"
      height={height ?? "auto"}
    >
      <TextBold
        fontSize={["16px", "16px", "18px"]}
        mb={["13px", "13px", "6px"]}
        textAlign="left"
      >
        {children}
      </TextBold>
    </FlexBoxColumn>
  );
};


const MoreFilters = ({
                       bgcolor,
                       color,
                       towbar,
                       setTowbar,
                       manual,
                       setManual,
                       automatic,
                       setAutomatic,
                        isOpenSearchMini,
  carClass,
  transmission,
  startDate,
  endDate,
  distance
                     }) => {

  const history = useHistory();

  const firstUpdate = useRef(true);
  useEffect(() => {
    if(firstUpdate.current)
      firstUpdate.current = false;
    else
    search(history, {
      carClass,
      transmission,
      startDate,
      endDate,
      distance,
      towbar,
    });
  },[towbar, transmission])

  return (
    <Box
      justifyContent={["center", "space-evenly", "space-evenly"]}
      flexWrap={["wrap", "wrap", "nowrap"]}
      display={"flex"}
      flexDirection={["column", "row", "row"]}
      height={["auto", "auto", "80px"]}
      marginTop={["0px", "0px", isOpenSearchMini ? "0px" : "15px"]}
      bgcolor={bgcolor}
      width="auto"
      color={color}
      style={{
        filter: !isOpenSearchMini ? "drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.05))" : "0",
      }}
      pl={["30px", "0px", "20px"]}
      pr={["30px", "0px", "0px"]}
      py={["15px", "15px", "0px"]}
      borderRadius="5px"
      component="form"
    >
      <FlexBoxRow mr={[0, "5%", "5%"]} py={["8px", 0, 0]}>
        <StyledCheckbox
          checked={towbar}
          onChange={(event) => setTowbar(event.target.checked)}
        />
        <img src={towbarIcon} alt={"Dragkrok"} style={{marginLeft: 8}}/>
        <TextBold
          fontSize={["16px", "16px", "18px"]}
          textAlign="left"
          style={{marginLeft: 8}}
        >
          Dragkrok
        </TextBold>
      </FlexBoxRow>

      <FlexBoxRow mr={[0, "5%", "5%"]} py={["8px", 0, 0]}>
        <StyledCheckbox
          checked={automatic}
          onChange={(event) => setAutomatic(event.target.checked)}
        />
        <img src={automaticIcon} alt={"Automat"} style={{marginLeft: 8}}/>
        <TextBold
          fontSize={["16px", "16px", "18px"]}
          textAlign="left"
          style={{marginLeft: 8}}
        >
          Automat
        </TextBold>
      </FlexBoxRow>
      <FlexBoxRow mr={[0, "5%", "5%"]} py={["8px", 0, 0]}>
        <StyledCheckbox
          checked={manual}
          onChange={(event) => setManual(event.target.checked)}
        />
        <img
          src={transmissionIconBlack}
          alt={"Manuell"}
          style={{marginLeft: 8}}
        />
        <TextBold
          fontSize={["16px", "16px", "18px"]}
          textAlign="left"
          style={{marginLeft: 8}}
        >
          Manuell
        </TextBold>
      </FlexBoxRow>
    </Box>
  );
};

/**
 * Search bar
 * @author Erik Jonasson
 * TODO fix temporary fix duplicated code //Oscar
 */
const Search = ({bgcolor, color, inputBgcolor, altView, distanceChanged}) => {

  const carClasses = [
    {value: "Personbil", label: "Personbil"},
    {value: "Buss", label: "Minibuss"},
    {value: "Lastbil", label: "Lätt lastbil"},
    {value: "Släp", label: "Släp"},
  ];

  const [carClass, setCarClass] = useState(carClasses[0].value);
  const [transmission, setTransmission] = useState(transmissionTypes[0].value);
  const [towbar, setTowbar] = useState(false);
  const [automatic, setAutomatic] = useState(true);
  const [manual, setManual] = useState(true);
  const [distance, setDistance] = useState("0");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isOpenFilter, setIsOpenFilter] = useState({
    value: false,
    text: "Visa filtrering",
  });

  const [isOpenSearchMini, setIsOpenSearchMini] = useState(false);
  const toggleIsOpenSearchMini = () => {
    return setIsOpenSearchMini(!isOpenSearchMini)
  }

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (manual && automatic) setTransmission(transmissionTypes[0].value);
    else if (manual) setTransmission(transmissionTypes[2].value);
    else if (automatic) setTransmission(transmissionTypes[1].value);
    else setTransmission(transmissionTypes[3].value);
  }, [automatic, manual]);

  const onChangeDistance = (value) => {
    if (
      (value === "" || (!isNaN(value) && !isNaN(parseFloat(value)))) &&
      !value.includes(".") &&
      value.length < 6
    ) {
      setDistance(value);
      if (distanceChanged !== undefined) {
        distanceChanged(value);
      }
    }

  };

  const history = useHistory();
  const searchCars = (event) => {
    event.preventDefault();
    search(history, {
      carClass,
      transmission,
      startDate,
      endDate,
      distance,
      towbar,
    });
  };

  const {openHours, bookingTime} = useContext(Context);
  useEffect(() => {
    if (bookingTime !== null) {
      setCarClass(bookingTime.carClass);
      setTransmission(bookingTime.transmission);
      setAutomatic(bookingTime.transmission === transmissionTypes[1].value || bookingTime.transmission === transmissionTypes[0].value);
      setManual(bookingTime.transmission === transmissionTypes[2].value || bookingTime.transmission === transmissionTypes[0].value);
      setDistance(bookingTime.distance);
      setStartDate(bookingTime.startDate);
      setEndDate(bookingTime.endDate);
      setTowbar(bookingTime.towbar);
    } else {
      let firstDate = getNextOpenTime(new Date(), openHours);
      setStartDate(firstDate);
      let nextDate = new Date(firstDate);
      nextDate.setDate(nextDate.getDate() + 1);
      setEndDate(nextDate);
    }
  }, [bookingTime, openHours]);


  const handleOpenFilter = () => {
    setIsOpenFilter({
      value: !isOpenFilter.value,
      text: !isOpenFilter.value ? "Stäng filtrering" : "Visa filtrering",
    });
  };

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {passive: true});

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  const classes = useStyles();
  return (
    <>
      {altView &&
      <>
        <Fade in={isOpenSearchMini}>
          <Box style={{
            position: "fixed", width: "100vw", height: "100vh", backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 18,
          }}/>
        </Fade>

        <Slide in={sm ? scrollPosition > 600: scrollPosition > 330} timeout={{enter: 300, exit: 300}} mountOnEnter unmountOnExit
               addEndListener={() => {
                 setIsOpenSearchMini(false)
               }
               }>
          <Box position={"fixed"} mt={"0px"} width={"100%"} style={{zIndex: 20, cursor: "pointer"}} onClick={toggleIsOpenSearchMini}>
            <SearchMini startDate={Moment(startDate).format("yyyy-MM-DD HH:mm")}
                        endDate={Moment(endDate).format("yyyy-MM-DD HH:mm")}
                        transmission={transmission}
                        distance={distance}
                        toggleIsOpenSearchMini={toggleIsOpenSearchMini}
            />

          </Box>
        </Slide>
        <Slide in={isOpenSearchMini} timeout={{enter: 300, exit: 300}} mountOnEnter unmountOnExit>
          <Box position={"fixed"} width={"100%"} style={{zIndex: 19, backgroundColor: "#FFFFFF"}} mt={"75px"}>
            <Container maxWidth={"md"} bgcolor={"#FFFFFF"}>
              <FlexBoxRow
                justifyContent={["center", "center", "space-between"]}
                flexWrap={["wrap", "wrap", "nowrap"]}
                height={["auto", "auto", "128px"]}
                bgcolor={bgcolor}
                width="auto"
                color={color}
                style={{
                  filter: (!isOpenSearchMini || sm) ? "drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.05))" : "0",
                }}
                pl={[altView ? "0px" : "25px", altView ? "20px" : "25px", "20px"]}
                pr={[altView ? "0px" : "25px", altView ? "20px" : "25px", "20px"]}
                py={["30px", "30px", "0px"]}
                borderRadius="5px"
                component="form"
              >
                <Box
                  width={["100%", "47.5%", altView ? "130px" : "auto"]}
                  mr={[0, "5%", 0]}
                  display={{xs: altView ? "none" : "inline", sm: "block"}}
                >
                  <InputLabel height={["auto", "auto", altView ? "44px" : "auto"]}>
                    Fordonstyp
                  </InputLabel>
                  <Box bgcolor={inputBgcolor} borderRadius={5}>
                    <Dropdown
                      width={altView ? "auto" : ["auto", "auto", "200px"]}
                      height={"43px"}
                      value={carClass}
                      options={carClasses}
                      borderRadius={"5px"}
                      onChange={(value) => setCarClass(value)}
                      bgcolor={inputBgcolor}

                    />
                  </Box>
                </Box>
                <Box
                  width={["100%", "47.5%", altView ? "155px" : "auto"]}
                  mt={["15px", "0px", "0px"]}
                >
                  <InputLabel height={["auto", "auto", altView ? "44px" : "auto"]}>
                    Upphämtning
                  </InputLabel>
                  <Box bgcolor={inputBgcolor} borderRadius={5}>
                    <Datepicker
                      filterTime={isOpenTimeFunc(openHours)}
                      filterDate={isOpenDateFunc(openHours)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      selected={startDate}
                      handleSelect={(date) => changeStartDate(date.value, openHours, endDate, setStartDate, setEndDate)}
                      showTimeSelect
                    />
                  </Box>
                </Box>
                <Box
                  width={["100%", "47.5%", altView ? "155px" : "auto"]}
                  mr={[0, "5%", 0]}
                  mt={["15px", "15px", "0px"]}
                >
                  <InputLabel height={["auto", "auto", altView ? "44px" : "auto"]}>
                    Återlämning
                  </InputLabel>
                  <Box bgcolor={inputBgcolor} borderRadius={5}>
                    <Datepicker
                      selected={endDate}
                      minDate={startDate}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      handleSelect={(date) => changeEndDate(date.value, startDate, setEndDate)}
                      showTimeSelect
                    />
                  </Box>
                </Box>
                {altView && (
                  <Box width={["100%", "47.5%", "107px"]} mt={["15px", "15px", "0px"]} borderRadius={5}>
                    <InputLabel height={["auto", "auto", altView ? "44px" : "auto"]}>
                      Körsträcka
                    </InputLabel>
                    <Box bgcolor={inputBgcolor} borderRadius={5}>
                      <StyledInput
                        height={"43px"}
                        value={distance}
                        onChange={onChangeDistance}
                        postfix={"km"}
                        inputMode="numeric"
                        mr={"10px"}
                        borderRadius={"5px"}
                      />
                    </Box>
                  </Box>
                )}

                {altView && (
                  <Box
                    width={["100%", "47.5%", "115px"]}
                    mr={[0, "5%", 0]}
                    mt={["15px", "15px", "0px"]}
                    marginBottom={["16px", 0, 0]}
                  >
                    <Box height={[24, 32, 43]}/>
                    <Button
                      style={{
                        width: "100%",
                        height: 43,
                        backgroundColor: sm && "#FFF",
                        boxShadow: "none",
                      }}
                      marginTop={["auto", "auto", "44px"]}
                      variant="contained"
                      onClick={handleOpenFilter}
                      color={sm ? "inherit" : "secondary"}
                    >
                      <FormatListBulleted/>
                      <Box width={["auto", "auto", "90px"]}>
                        <p style={{marginLeft: 8}}>{isOpenFilter.text}</p>
                      </Box>
                    </Button>
                  </Box>
                )}

                {altView && xs && isOpenFilter.value && (
                  <Fade in={isOpenFilter.value} timeout={{enter: 300, exit: 300}}>
                    <MoreFilters
                      bgcolor={bgcolor}
                      color={color}
                      towbar={towbar}
                      setTowbar={setTowbar}
                      manual={manual}
                      setManual={setManual}
                      automatic={automatic}
                      setAutomatic={setAutomatic}
                      carClass={carClass}
                      transmission={transmission}
                      startDate={startDate}
                      endDate={endDate}
                      distance={distance}
                    />
                  </Fade>
                )}

                <Box
                  display={{xs: altView ? "block" : "none", sm: "none"}}
                  mt={["15px", "15px", "0px"]}
                >
                  <CarPicker carClass={carClass} setCarClass={setCarClass}/>
                </Box>

                <Box
                  className={classes.buttonWrapper}
                  width={["100%", "47.5%", altView ? "105px" : "144px"]}
                  height={["auto", "43px", "auto"]}
                  marginTop={["32px", "47px", altView ? "44px" : "24px"]}
                >
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={searchCars}
                    type="submit"
                  >
                    Sök
                  </Button>
                </Box>
              </FlexBoxRow>
              {altView && !xs && isOpenFilter.value && (
                <Fade in={isOpenFilter.value} timeout={{enter: 300, exit: 300}}>
                  <Box>
                    <MoreFilters
                      bgcolor={bgcolor}
                      color={color}
                      towbar={towbar}
                      setTowbar={setTowbar}
                      manual={manual}
                      setManual={setManual}
                      automatic={automatic}
                      setAutomatic={setAutomatic}
                      isOpenSearchMini={isOpenSearchMini}
                      carClass={carClass}
                      transmission={transmission}
                      startDate={startDate}
                      endDate={endDate}
                      distance={distance}
                    />
                  </Box>
                </Fade>
              )}
            </Container>
          </Box>
        </Slide>
      </>
      }
      <Container maxWidth={"md"} style={{padding: altView ? "auto" : 0}}>
        <FlexBoxRow
          justifyContent={["center", "center", "space-between"]}
          flexWrap={["wrap", "wrap", "nowrap"]}
          height={["auto", "auto", "128px"]}
          bgcolor={bgcolor}
          width="auto"
          color={color}
          style={{
            filter: (!isOpenSearchMini || sm) ? "drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.05))" : "0",
          }}
          pl={[altView ? "0px" : "25px", altView ? "20px" : "25px", "20px"]}
          pr={[altView ? "0px" : "25px", altView ? "20px" : "25px", "20px"]}
          py={["30px", "30px", "0px"]}
          borderRadius="5px"
          component="form"
        >
          <Box
            width={["100%", "47.5%", altView ? "130px" : "auto"]}
            mr={[0, "5%", 0]}
            display={{xs: altView ? "none" : "inline", sm: "block"}}
          >
            <InputLabel height={["auto", "auto", altView ? "44px" : "auto"]}>
              Fordonstyp
            </InputLabel>
            <Box bgcolor={inputBgcolor} borderRadius={5}>
              <Dropdown
                width={altView ? "auto" : ["auto", "auto", "200px"]}
                height={"43px"}
                value={carClass}
                options={carClasses}
                borderRadius={"5px"}
                onChange={(value) => setCarClass(value)}
                bgcolor={inputBgcolor}

              />
            </Box>
          </Box>
          <Box
            width={["100%", "47.5%", altView ? "155px" : "auto"]}
            mt={["15px", "0px", "0px"]}
          >
            <InputLabel height={["auto", "auto", altView ? "44px" : "auto"]}>
              Upphämtning
            </InputLabel>
            <Box bgcolor={inputBgcolor} borderRadius={5}>
              <Datepicker
                filterTime={isOpenTimeFunc(openHours)}
                filterDate={isOpenDateFunc(openHours)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                selected={startDate}
                handleSelect={(date) => changeStartDate(date.value, openHours, endDate, setStartDate, setEndDate)}
                showTimeSelect
              />
            </Box>
          </Box>
          <Box
            width={["100%", "47.5%", altView ? "155px" : "auto"]}
            mr={[0, "5%", 0]}
            mt={["15px", "15px", "0px"]}
          >
            <InputLabel height={["auto", "auto", altView ? "44px" : "auto"]}>
              Återlämning
            </InputLabel>
            <Box bgcolor={inputBgcolor} borderRadius={5}>
              <Datepicker
                selected={endDate}
                minDate={startDate}
                selectsEnd={true}
                startDate={startDate}
                endDate={endDate}
                handleSelect={(date) => changeEndDate(date.value, startDate, setEndDate)}
                showTimeSelect
              />
            </Box>
          </Box>
          {altView && (
            <Box width={["100%", "47.5%", "107px"]} mt={["15px", "15px", "0px"]} borderRadius={5}>
              <InputLabel height={["auto", "auto", altView ? "44px" : "auto"]}>
                Körsträcka
              </InputLabel>
              <Box bgcolor={inputBgcolor} borderRadius={5}>
                <StyledInput
                  height={"43px"}
                  value={distance}
                  onChange={onChangeDistance}
                  postfix={"km"}
                  inputMode="numeric"
                  mr={"10px"}
                  borderRadius={"5px"}
                />
              </Box>
            </Box>
          )}

          {altView && (
            <Box
              width={["100%", "47.5%", "115px"]}
              mr={[0, "5%", 0]}
              mt={["15px", "15px", "0px"]}
              marginBottom={["16px", 0, 0]}
            >
              <Box height={[24, 32, 43]}/>
              <Button
                style={{
                  width: "100%",
                  height: 43,
                  backgroundColor: sm && "#FFF",
                  boxShadow: "none",
                }}
                marginTop={["auto", "auto", "44px"]}
                variant="contained"
                onClick={handleOpenFilter}
                color={sm ? "inherit" : "secondary"}
              >
                <FormatListBulleted/>
                <Box width={["auto", "auto", "90px"]}>
                  <p style={{marginLeft: 8}}>{isOpenFilter.text}</p>
                </Box>
              </Button>
            </Box>
          )}

          {altView && xs && isOpenFilter.value && (
            <Fade in={isOpenFilter.value} timeout={{enter: 300, exit: 300}}>
              <MoreFilters
                bgcolor={bgcolor}
                color={color}
                towbar={towbar}
                setTowbar={setTowbar}
                manual={manual}
                setManual={setManual}
                automatic={automatic}
                setAutomatic={setAutomatic}
                carClass={carClass}
                transmission={transmission}
                startDate={startDate}
                endDate={endDate}
                distance={distance}
              />
            </Fade>
          )}

          <Box
            display={{xs: altView ? "block" : "none", sm: "none"}}
            mt={["15px", "15px", "0px"]}
          >
            <CarPicker carClass={carClass} setCarClass={setCarClass}/>
          </Box>

          <Box
            className={classes.buttonWrapper}
            width={["100%", "47.5%", altView ? "105px" : "144px"]}
            height={["auto", "43px", "auto"]}
            marginTop={["32px", "47px", altView ? "44px" : "24px"]}
          >
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={searchCars}
              type="submit"
            >
              Sök
            </Button>
          </Box>
        </FlexBoxRow>
        {altView && !xs && isOpenFilter.value && (
          <Fade in={isOpenFilter.value} timeout={{enter: 300, exit: 300}}>
            <Box>
              <MoreFilters
                bgcolor={bgcolor}
                color={color}
                towbar={towbar}
                setTowbar={setTowbar}
                manual={manual}
                setManual={setManual}
                automatic={automatic}
                setAutomatic={setAutomatic}
                carClass={carClass}
                transmission={transmission}
                startDate={startDate}
                endDate={endDate}
                distance={distance}
              />
            </Box>
          </Fade>
        )}
      </Container>

    </>
  );
};

export default Search;
