import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

import BurgerButton from "./burgerButton/BurgerButton";

import logoRed from "../../assets/images/logo_red.png";
import logoWhiteRed from "../../assets/images/logo_white_red.png";
import "./header.scss";
import { Context } from "../../context/Store";
import { auth } from "../../database/firebase";
import useClickOutside from "../../utils/useClickOutside";

/**
 * Header component
 * @author: Erik Jonasson
 */
const Header = () => {
  const headerRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { user, setCustomerInfo, setUser } = useContext(Context);

  const onClick = () => {
    setOpen((prev) => !prev);
  };

  const onClickOutside = () => {
    setOpen(false);
  };
  useClickOutside(headerRef, onClickOutside);

  const history = useHistory();

  const { pathname } = useLocation();
  // Use red and white logo on homepage
  const logo = pathname === "/" ? logoWhiteRed : logoRed;
  // Change style based on page
  const pageClass = useMemo(() => {
    if (pathname === "/") {
      return "headerHomepage";
    }
    return "";
  }, [pathname]);

  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  const [scrollTop, setScrollTop] = useState(true);

  const logOut = () => {
    auth.signOut().catch();
    setUser(null);
    setCustomerInfo(null);
  };

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop === 0);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <header
      className={`header ${pageClass} ${scrollTop ? "headerScrollTop" : ""}`}
      ref={headerRef}
    >
      <NavLink to="/" className="headerLogoWrapper">
        <img className="headerLogo" src={logo} alt="" />
      </NavLink>

      <BurgerButton open={open} onClick={onClick} />

      <nav
        className="headerNavbar"
        style={{ transform: open ? "translateX(0)" : "translateX(100%)" }}
      >
        <div>
          <NavLink exact to="/" className="headerHome">
            Hem
          </NavLink>
          <NavLink exact to="/vara-fordon">
            Våra fordon
          </NavLink>
          <NavLink exact to="/om-oss">
            Om oss
          </NavLink>
          <NavLink exact to="/oppetider">
            Öppettider
          </NavLink>
          {/* <NavLink exact to="/prislista">
          Prislista
        </NavLink> */}
          <NavLink exact to="/faq">
            F.A.Q
          </NavLink>

          {/* {user ? (
            <>
              <NavLink exact to="/profil/mina-sidor">
                Profil
              </NavLink>
              <h3 style={{ cursor: "pointer" }} onClick={logOut}>
                Logga ut
              </h3>
            </>
          ) : (
            <>
              <NavLink exact to="/profil/logga-in">
                Logga in
              </NavLink>
              <NavLink exact to="/profil/registrera">
                Registrera
              </NavLink>
            </>
          )} */}
        </div>
      </nav>
    </header>
  );
};

export default Header;
