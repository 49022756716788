import React from "react";

import carIcon from "../../assets/icons/carTypes/car.svg";
import carSelectedIcon from "../../assets/icons/carTypes/carSelected.svg";
import vanIcon from "../../assets/icons/carTypes/van.svg";
import vanSelectedIcon from "../../assets/icons/carTypes/vanSelected.svg";
import truckIcon from "../../assets/icons/carTypes/truck.svg";
import truckSelectedIcon from "../../assets/icons/carTypes/truckSelected.svg";
import trailerIcon from "../../assets/icons/carTypes/trailer.svg";
import trailerSelectedIcon from "../../assets/icons/carTypes/trailerSelected.svg";
import { Box } from "@material-ui/core";

/**
 * Component for selecting car type
 * @param {string} carClass Selected carClass
 * @param {function} setCarClass Setter for carClass
 *
 * @author: Erik Jonasson
 */
const CarPicker = ({ carClass, setCarClass }) => {
  const icons = [
    { default: carIcon, selected: carSelectedIcon, value: "Personbil" },
    { default: vanIcon, selected: vanSelectedIcon, value: "Buss" },
    { default: truckIcon, selected: truckSelectedIcon, value: "Lastbil" },
    { default: trailerIcon, selected: trailerSelectedIcon, value: "Släp" },
  ];

  return (
    <Box
      maxWidth="500px"
      mx="auto"
      display="flex"
      justifyContent="space-between"
      px="10px"
    >
      {icons.map((icon, index) => {
        return (
          <img
            style={{ width: "20%", cursor: "pointer" }}
            src={icon.value === carClass ? icon.selected : icon.default}
            key={index}
            onClick={() => setCarClass(icon.value)}
            alt=""
          />
        );
      })}
    </Box>
  );
};

export default CarPicker;
