import {Box} from "@material-ui/core";
import FlexBoxRow from "../flexBox/FlexBoxRow";
import StyledCheckbox from "../styledCheckbox/StyledCheckbox";
import towbarIcon from "../../assets/icons/cardIcons/car_type_black.svg";
import {TextBold} from "../text/Text";
import automaticIcon from "../../assets/icons/cardIcons/automatic.svg";
import transmissionIconBlack from "../../assets/icons/cardIcons/transmissionBlack.svg";
import React from "react";

/**
 * Component for displaying more filters
 * @param bgcolor - background color
 * @param color
 * @param towbar - state boolean for towbar
 * @param setTowbar
 * @param manual - state boolean for manual
 * @param setManual
 * @param automatic - state boolean for automatic
 * @param setAutomatic
 * @returns {JSX.Element}
 * @constructor
 */
const MoreFilters = ({
                       bgcolor,
                       color,
                       towbar,
                       setTowbar,
                       manual,
                       setManual,
                       automatic,
                       setAutomatic,
                     }) => {
  return (
    <Box
      justifyContent={["center", "space-evenly", "space-evenly"]}
      flexWrap={["wrap", "wrap", "nowrap"]}
      display={"flex"}
      flexDirection={["column", "row", "row"]}
      height={["auto", "auto", "80px"]}
      marginTop={["0px", "0px", "15px"]}
      bgcolor={bgcolor}
      width="auto"
      color={color}
      style={{
        filter: "drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.05))",
      }}
      pl={["30px", "0px", "20px"]}
      pr={["30px", "0px", "0px"]}
      py={["15px", "15px", "0px"]}
      borderRadius="5px"
      component="form"
    >
      <FlexBoxRow mr={[0, "5%", "5%"]} py={["8px", 0, 0]}>
        <StyledCheckbox
          checked={towbar}
          scale={1.5}
          onChange={(event) => setTowbar(event.target.checked)}
        />
        <img src={towbarIcon} alt={"Dragkrok"} style={{marginLeft: 8}}/>
        <TextBold
          fontSize={["16px", "16px", "18px"]}
          textAlign="left"
          style={{marginLeft: 8}}
        >
          Dragkrok
        </TextBold>
      </FlexBoxRow>

      <FlexBoxRow mr={[0, "5%", "5%"]} py={["8px", 0, 0]}>
        <StyledCheckbox
          checked={automatic}
          scale={1.5}
          onChange={(event) => setAutomatic(event.target.checked)}
        />
        <img src={automaticIcon} alt={"Automat"} style={{marginLeft: 8}}/>
        <TextBold
          fontSize={["16px", "16px", "18px"]}
          textAlign="left"
          style={{marginLeft: 8}}
        >
          Automat
        </TextBold>
      </FlexBoxRow>
      <FlexBoxRow mr={[0, "5%", "5%"]} py={["8px", 0, 0]}>
        <StyledCheckbox
          checked={manual}
          scale={1.5}
          onChange={(event) => setManual(event.target.checked)}
        />
        <img
          src={transmissionIconBlack}
          alt={"Manuell"}
          style={{marginLeft: 8}}
        />
        <TextBold
          fontSize={["16px", "16px", "18px"]}
          textAlign="left"
          style={{marginLeft: 8}}
        >
          Manuell
        </TextBold>
      </FlexBoxRow>
    </Box>
  );
};

export default MoreFilters;