import { Checkbox, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "30px",
    height: "30px",
    padding: "0px",
  },
}));

const StyledCheckbox = ({ checked, onChange, scale, disabled }) => {
  const classes = useStyles();
  return (
    <Checkbox
      color="primary"
      className={classes.root}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      style={{
        transform: `scale(${scale ?? 1})`,
      }}
    />
  );
};

export default StyledCheckbox;
