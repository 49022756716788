import { Box, Button, makeStyles } from "@material-ui/core";
import React from "react";
import { ReactComponent as LoadingIcon } from "../../assets/icons/loading.svg";
import FlexBoxRow from "../flexBox/FlexBoxRow";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    width: "100%",
    height: "100%",
    boxShadow: "none",
    textTransform: "none",
    "& .MuiButton-contained": {},
  },
}));

const StyledButton = ({
  width,
  height,
  color,
  onClick,
  children,
  type,
  loading,
  padding,
  TextColor,
  variant,
}) => {
  const classes = useStyles();
  let defaultTextColor = "#FFF";
  if (color === "secondary") {
    defaultTextColor = "#000";
  }
  return (
    <Box width={width} height={height}>
      <Button
        className={classes.root}
        style={{ padding: padding }}
        variant={variant ?? "contained"}
        color={color ?? "primary"}
        onClick={onClick}
        type={type}
      >
        <FlexBoxRow padding={padding ?? "6px 16px"}>
          <Box display="inline">{children}</Box>
          {loading !== undefined && loading && (
            <FlexBoxRow ml="5px">
              <LoadingIcon
                width="15px"
                height="15px"
                fill={TextColor ?? defaultTextColor}
              />
            </FlexBoxRow>
          )}
        </FlexBoxRow>
      </Button>
    </Box>
  );
};

export default StyledButton;
