import { Box, Container, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { functions } from "../../../database/firebase";
import CarCardInfo from "../../carCardInfo/CarCardInfo";
import { ReactComponent as LoadingIcon } from "../../../assets/icons/loading.svg";
import CarPicker from "../../carPicker/CarPicker";
import MoreFilters from "../../search/MoreFilters";
import { transmissionTypes } from "../../../types";
import { TextBold } from "../../text/Text";
import { search } from "../../../utils/Search";

/**
 * Our cars site
 * @author Erik Jonasson
 */
const OurCars = () => {
  useEffect(() => {
    document.title = "Våra bilar";
  }, []);

  const [carClass, setCarClass] = useState("Personbil");
  const [isLoading, setIsLoading] = useState({});
  const [towbar, setTowbar] = useState(false);
  const [automatic, setAutomatic] = useState(true);
  const [manual, setManual] = useState(true);
  const [carList, setCarList] = useState({});

  const getTransmission = () => {
    if (manual && automatic) return transmissionTypes[0].value;
    else if (manual) return transmissionTypes[2].value;
    else if (automatic) return transmissionTypes[1].value;
    else return transmissionTypes[0].value;
  };

  useEffect(() => {
    const getAllCars = functions.httpsCallable("cars-getVisible");
    (async () => {
      if (isLoading[carClass]) return;
      const transmission = getTransmission();
      setIsLoading((prev) => {
        prev[carClass] = true;
        return { ...prev };
      });
      try {
        const cars = await getAllCars({
          carClass: carClass,
          towbar: towbar,
          transmission: transmission,
        }).then((res) => {
          return JSON.parse(res.data);
        });
        cars.sort((a, b) => a.priceGroup.localeCompare(b.priceGroup));

        setCarList((prev) => {
          prev[carClass] = cars;
          return { ...prev };
        });
        setIsLoading((prev) => {
          prev[carClass] = false;
          return { ...prev };
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, [carClass, towbar, automatic, manual]); // eslint-disable-line react-hooks/exhaustive-deps

  const history = useHistory();
  // const searchCars = () => {
  //   const startDate = new Date();
  //   const endDate = new Date();
  //   const transmission = getTransmission();
  //   const distance = "0";
  //   search(history, {
  //     carClass,
  //     transmission,
  //     startDate,
  //     endDate,
  //     distance,
  //     towbar,
  //   });
  // };

  const navigateToStartPage = () => {
    history.push("/");
  }

  return (
    <Container maxWidth="md">
      <Box mb={"30px"} mt={"30px"}>
        <MoreFilters
          bgcolor={"#FFF"}
          color={"#000"}
          towbar={towbar}
          setTowbar={setTowbar}
          manual={manual}
          setManual={setManual}
          automatic={automatic}
          setAutomatic={setAutomatic}
        />
      </Box>
      <Box mt="30px" mb="40px" borderTop="2px solid #EEE" my={2} pt="30px">
        <CarPicker carClass={carClass} setCarClass={setCarClass} />
      </Box>
      <Box mb={["60px", "90px"]} mt="30px">
        {isLoading[carClass] === undefined || isLoading[carClass] === true ? (
          <LoadingIcon width="150px" height="150px" fill="#E0E0E0" />
        ) : (
          <>
            {carList[carClass].length > 0 ? (
              <Grid container spacing={3}>
                {carList[carClass].map((car) => {
                  return (
                    <Grid item key={car.modell} xs={12} sm={6} md={12}>
                      <CarCardInfo
                        car={car}
                        onSelect={navigateToStartPage}
                        height="100%"
                        hidePrice
                        buttonText={"TILL BOKNINGSSIDAN"}
                        buttonFontSize={"13px"}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <TextBold fontSize={24}>
                Det finns inga bilar med den valda filtreringen
              </TextBold>
            )}
          </>
        )}
      </Box>
    </Container>
  );
};

export default OurCars;
