import { Container } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import FlexBoxRow from "../../flexBox/FlexBoxRow";
import RegisterForm from "../../registerForm/RegisterForm";
import { TextBold } from "../../text/Text";
import { functions } from "../../../database/firebase";
import FlexBoxColumn from "../../flexBox/FlexBoxColumn";

const Register = () => {
  useEffect(() => {
    document.title = "Registrera";
  }, []);

  const [errorMessage, setErrorMessage] = useState([]);

  const [userCreated, setUserCreated] = useState(false);

  const submit = async (inputFields) => {
    const createUser = functions.httpsCallable("customer-createUser");

    const response = await createUser(inputFields);
    console.log(response);

    if (
      response.data &&
      response.data.status &&
      response.data.status === "error"
    ) {
      setErrorMessage(response.data.message);
      return;
    }

    console.log(inputFields);
    setUserCreated(true);
  };

  const history = useHistory();
  const goToLoginPage = () => {
    history.push("/profil/logga-in");
  };

  return (
    <Container maxWidth="md">
      <FlexBoxRow
        mt={["30px", "50px", "70px"]}
        mb={["60px", "90px"]}
        justifyContent="center"
      >
        <FlexBoxColumn>
          {errorMessage && errorMessage.length > 0 && (
            <TextBold fontSize="24px" color="red">
              {errorMessage}
            </TextBold>
          )}
          {userCreated ? (
            <TextBold fontSize="24px">
              Du har nu fått en e-postbekräftelse. För att fortsätta var vänlig
              bekräfta din e-mail genom att kolla din inkorg.
            </TextBold>
          ) : (
            <RegisterForm
              submitForm={submit}
              loginInstead={goToLoginPage}
              register
            />
          )}
        </FlexBoxColumn>
      </FlexBoxRow>
    </Container>
  );
};

export default Register;
