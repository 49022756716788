import { Box, Input, makeStyles } from "@material-ui/core";
import React from "react";
import { InputMask } from '@react-input/mask';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Replica",
    "& .MuiInput-input": {
      paddingLeft: "10px",
    },
    width: "100%",
    height: "100%",
    "& input:required:invalid": {
      border: "1px solid red",
    },
  },
}));

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;
  return <InputMask {...other} ref={inputRef} mask="ÅÅMMDD-XXXX" replacement={{ Å: /\d/, M: /\d/, D: /\d/, X: /\d/ }} showMask />;
};

/**
 * An input field that only allows input on the form 999999-9999
 * @author Erik Jonasson
 */
const PersonNumberInput = ({
  value,
  onChange,
  inputSize,
  width,
  height,
  bgcolor,
  type,
  disabled,
}) => {
  const classes = useStyles();
  return (
    <Box width={width} height={height} bgcolor={bgcolor ?? "inherit"}>
      <Input
        inputProps={{ size: inputSize ?? 9 }}
        className={classes.root}
        disableUnderline
        value={value}
        onChange={(event) => onChange(event.target.value)}
        type={type}
        disabled={disabled}
        inputComponent={TextMaskCustom}
        placeholder="ÅÅMMDD-XXXX"
      ></Input>
    </Box>
  );
};

export default PersonNumberInput;
