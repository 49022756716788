import { Box, Container, Hidden } from "@material-ui/core";
import React, { useContext, useEffect, useMemo } from "react";
import { Context } from "../../../context/Store";
import FlexBoxRow from "../../flexBox/FlexBoxRow";
import { Text, TextBold } from "../../text/Text";
import { ReactComponent as LoadingIcon } from "../../../assets/icons/loading.svg";

const OpeningHours = () => {
  useEffect(() => {
    document.title = "Öppettider";
  }, []);

  const { openHours, standardTimes, isOpenHoursLoading } = useContext(Context);

  const holidays = useMemo(() => {
    if (!openHours?.redDays) {
      return null;
    }
    let holidays = openHours.redDays.map((day) => {
      const from = new Date(day.FRAN);
      const to = new Date(day.TILL);
      const time = `${from.getHours()}-${to.getHours()}`;
      const date = `${from.getDate()}/${from.getMonth() + 1}`;
      return {
        day: day.DAG,
        time: time,
        date: date,
        closed: day.STANGT,
        month: from.getMonth(),
        newMonth: false,
      };
    });

    let month = null;
    for (const holiday of holidays) {
      if (month !== null && month !== holiday.month) {
        holiday.newMonth = true;
      }
      month = holiday.month;
    }
    return holidays;
  }, [openHours]);

  return (
    <Container maxWidth="md">
      <TextBold
        component="h1"
        fontSize={["24px", "24px", "35px"]}
        mt="30px"
        mb="40px"
      >
        Öppettider Folkes Biluthyrning
      </TextBold>
      {isOpenHoursLoading ? (
        <Box mt="30px">
          <LoadingIcon width="150px" height="150px" fill="#E0E0E0" />
        </Box>
      ) : (
        <FlexBoxRow
          padding={["16px", "30px", "50px"]}
          display="flex"
          flexDirection={["column", "row", "row"]}
          bgcolor={"#FFF"}
          border={"1.25px solid #F2F2F2"}
          borderRadius={5}
          alignItems="flex-start"
          maxWidth="720px"
          mx="auto"
          mb={["60px", "90px", "90px"]}
          textAlign="left"
        >
          <Box width={["100%", "50%"]} mr={[0, "10px", "25px"]}>
            <Text fontSize="24px">Ordinarie öppettider</Text>
            <Box my="15px" borderTop="1.5px solid #E0E0E0" width="100%"></Box>
            {standardTimes ? (
              standardTimes.map((day) => {
                return (
                  <FlexBoxRow
                    key={day.day}
                    my="6px"
                    fontSize="22px"
                    justifyContent={["space-between", "flex-start"]}
                  >
                    <Text width={"40%"}>{day.day}</Text>
                    <Text
                      display="inline"
                      style={{ fontVariantNumeric: "tabular-nums" }}
                    >
                      {day.time}
                    </Text>
                  </FlexBoxRow>
                );
              })
            ) : (
              <Text>Kunde inte hämta öppettider</Text>
            )}
          </Box>
          <Box
            width={["100%", "50%"]}
            ml={[0, "10px", "25px"]}
            mt={["50px", 0]}
          >
            <Text fontSize="24px">Avvikande öppettider</Text>
            <Box my="15px" borderTop="1.5px solid #E0E0E0" width="100%"></Box>
            {holidays ? (
              holidays.map((day) => {
                return (
                  <FlexBoxRow
                    key={day.date}
                    fontSize="16px"
                    lineHeight="1.4"
                    mt={day.newMonth ? "12px" : "0px"}
                  >
                    <Text width={["60%", "50%"]}>
                      {day.day} {"  "}
                    </Text>

                    <Text
                      width={["20%", "25%"]}
                      display="inline"
                      flexGrow={[1, 0]}
                      color={day.closed ? "red" : "black"}
                      textAlign={"center"}
                    >
                      {day.closed ? "Stängt" : day.time}
                    </Text>

                    <Text
                      width={["20%", "25%"]}
                      display="inline"
                      textAlign={"right"}
                    >
                      {day.date}
                    </Text>
                  </FlexBoxRow>
                );
              })
            ) : (
              <Text>Kunde inte hämta avvikande öppettider</Text>
            )}
          </Box>
        </FlexBoxRow>
      )}
    </Container>
  );
};

export default OpeningHours;
