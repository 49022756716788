import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { functions } from "../../../database/firebase";
import { ReactComponent as LoadingIcon } from "../../../assets/icons/loading.svg";

import Search from "../../search/Search";
import { TextBold } from "../../text/Text";
import "./home.scss";
import CarCarousel from "../../carCarousel/CarCarousel";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  underSearch: {
    marginTop: "70px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
    },
  },
}));

/**
 * Homepage
 * @author Erik Jonasson
 */
const Home = () => {
  const history = useHistory();

  history.listen((newLocation, action) => {
    if (history.action === "POP") {
      history.push("/");
    }
  });

  useEffect(() => {
    document.title = "Folkes biluthyrning";
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [isCampaignLoading, setIsCampaignLoading] = useState(true);
  const [carList, setCarList] = useState({});
  const [campaignCarList, setCampaignCarList] = useState({});

  useEffect(() => {
    const getPopularCars = functions.httpsCallable("cars-getPopularCars");
    (async () => {
      try {
        const cars = await getPopularCars().then((res) => {
          return JSON.parse(res.data);
        });
        cars.sort((a, b) => a.priceGroup.localeCompare(b.priceGroup));
        setCarList(cars);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
    const getCampaignCars = functions.httpsCallable("cars-getCampaignCars");
    (async () => {
      try {
        const cars = await getCampaignCars().then((res) => {
          return JSON.parse(res.data);
        });

        cars.sort((a, b) => a.priceGroup.localeCompare(b.priceGroup));
        setCampaignCarList(cars);
        setIsCampaignLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const classes = useStyles();
  return (
    <div className="home">
      <div className="homeImage" />
      <Container maxWidth="md">
        <Box
          color="#FFF"
          fontSize={["20px", "26px", "35px"]}
          textAlign={["center", "left"]}
          mt={["200px", "200px", "350px"]}
        >
          <TextBold mb={["25px", "30px", "45px"]}>
          Din lokala biluthyrare med det breda utbudet. Nu hittar ni oss i våra nya lokaler på Sellerigatan 5 i Lund!
          </TextBold>
          <Search
            bgcolor={["#FFF", "#FFF", "#FFFFFF33"]}
            color={["#000", "#000", "#FFF"]}
            inputBgcolor={["#EEE", "#EEE", "#FFF"]}
          />
        </Box>
        <Typography
          className={classes.underSearch}
          variant="h2"
          align="center"
          style={{ marginBottom: "30px" }}
        >
          Bilar på kampanj
        </Typography>
        <Box mt={["40px", "70px"]} mb={["60px", "90px"]}>
          {isCampaignLoading ? (
            <LoadingIcon width="150px" height="150px" fill="#E0E0E0" />
          ) : (
            <CarCarousel cars={campaignCarList} />
          )}
        </Box>
        <Typography
          className={classes.underSearch}
          variant="h2"
          align="center"
          style={{ marginBottom: "30px" }}
        >
          Några av våra populära fordon
        </Typography>
        <Box mt={["40px", "70px"]} mb={["60px", "90px"]}>
          {isLoading ? (
            <LoadingIcon width="150px" height="150px" fill="#E0E0E0" />
          ) : (
            <CarCarousel cars={carList} />
          )}
        </Box>
      </Container>
    </div>
  );
};

export default Home;
