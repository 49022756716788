import { Box, Input, InputAdornment, makeStyles } from "@material-ui/core";
import React from "react";
import { TextBold } from "../text/Text";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Replica",
    "& .MuiInput-input": {
      paddingLeft: "10px",
    },
    width: "100%",
    height: "100%",
    "& input:required:invalid": {
      border: "1px solid red",
    },
  },
}));

const StyledInput = (props) => {
  const {
    value,
    onChange,
    inputSize,
    bgcolor,
    type,
    postfix,
    inputMode,
    ...other
  } = props;
  const classes = useStyles();
  return (
    <Box {...other} bgcolor={bgcolor ?? "inherit"}>
      <Input
        inputProps={{ size: inputSize ?? 9, inputMode: inputMode }}
        className={classes.root}
        disableUnderline
        value={value}
        onChange={(event) => onChange(event.target.value)}
        type={type}
        disabled={props.disabled}
        endAdornment={
          postfix && (
            <InputAdornment position="end">
              <TextBold style={{ color: "#767676", fontSize: 14 }}>
                {postfix}
              </TextBold>
            </InputAdornment>
          )
        }
      ></Input>
    </Box>
  );
};

export default StyledInput;
