import dayjs from "dayjs";
import { daysBetween, msBetween } from "./date";

export const getPriceBase = (price) => {
  const type = price.base.type.match(
    /^(?<prefix>[A-Z]{1})0*(?<quantity>[1-9]{1}[0-9]*)$/
  );
  const prefix = type.groups.prefix;
  const quantity = parseInt(type.groups.quantity);

  const dayNrToDay = {
    1: "Söndag",
    2: "Måndag",
    3: "Tisdag",
    4: "Onsdag",
    5: "Torsdag",
    6: "Fredag",
    7: "Lördag",
  };

  let timeText = "";

  if (prefix === "D") {
    if (quantity === 1) {
      timeText = `Dygnshyra`;
    } else if (quantity === 30) {
      timeText = `Månadshyra`;
    } else if (quantity % 30 === 0) {
      timeText = `${quantity / 30} månader`;
    } else if (quantity === 7) {
      timeText = `Veckohyra`;
    } else if (quantity % 7 === 0) {
      timeText = `${quantity / 7} veckor`;
    } else {
      timeText = `${quantity} dagar`;
    }
  } else if (prefix === "T") {
    if (quantity === 1) {
      timeText = `Timhyra`;
    } else {
      timeText = `${quantity} timmar`;
    }
  } else if (prefix === "W") {
    timeText += dayNrToDay[Math.floor(quantity / 10)];
    timeText += " - ";
    timeText += dayNrToDay[Math.floor(quantity % 10)];
  }
  return timeText;
};

export const isHourPrice = (price) => {
  const type = price.base.type.match(
    /^(?<prefix>[A-Z]{1})0*(?<quantity>[1-9]{1}[0-9]*)$/
  );
  const prefix = type.groups.prefix;

  return prefix === "T" && price.day.quantity === 0;
};

export const tax = 1.25;
/**
 * Calculates price for a reservation. To find the best price the cheapest price principle is used.
 * Copied from admin site, removed cdw and added taxes before calculation
 *
 * To use interval time it is required that both start- and end time is within interval (i.e. no over days or over hours).
 *
 * @param {Date} startTime Start time of reservation.
 * @param {Date} endTime End time of reservation.
 * @param {String} distance Estimated distance in kilometer (km).
 * @param {Number} priceListReference price lists to be used.
 *
 * @returns {Object} Price-object
 */
export async function calculatePrice(
  start,
  end,
  distance,
  priceLists,
  carGroup,
  campaign = null
) {
  if (typeof distance === "string") {
    distance = Number(distance);
  }

  // Picks the latest allowed pricelist
  const priceList = priceLists.reduce((a, b) => {
    if (start > a.GILTIG_TILL || start < a.GILTIG_FRAN) {
      return b;
    }
    if (start > b.GILTIG_TILL || start < b.GILTIG_FRAN) {
      return a;
    }
    if (a.GILTIG_FRAN > b.GILTIG_FRAN) {
      return a;
    } else {
      return b;
    }
  });

  const relevantPriceLists = priceList.lists.filter(
    (price) => price.BILGRUPP === carGroup
  );

  // const isInsurance = priceListDefinition.FORSAKRINGSPRISLISTA !== 0;
  const isInsurance = false;
  let prices = [];
  start.setMilliseconds(0);
  start.setSeconds(0);
  end.setMilliseconds(0);
  end.setSeconds(0);

  let pengar = {
    total: {
      rent: 0,
    },
  };

  if (campaign) {
    const newStart = new Date(start);
    const newEnd = new Date(end);
    const campaignEnd = new Date(campaign.end._seconds * 1000);
    const newCampaignEnd = campaignEnd;
    const daysLeft = daysBetween(newStart, newCampaignEnd);
    const rentDays = daysBetween(newStart, newEnd);
    let normalDays = rentDays - daysLeft;

    const tEnd = new Date(newEnd.toString());
    if (normalDays > 0) {
      tEnd.setDate(tEnd.getDate() - normalDays);

      pengar = await calculatePrice(tEnd, newEnd, 20, priceLists, carGroup);
    }
  }

  relevantPriceLists.forEach((priceList) => {
    const type = priceList.TYP.match(
      /^(?<prefix>[A-Z]{1})0*(?<quantity>[1-9]{1}[0-9]*)$/
    );
    const prefix = type.groups.prefix;
    const quantity = Number(type.groups.quantity);

    // Addons is removed from customersite`s priceCalc (is calculated at other place)
    const price = {
      insurance: isInsurance,
      base: {
        type: priceList.TYP,
        unlimitedKm: priceList.FRI_KORSTRACKA ? true : false,
        quantity: 0,
        unitPrice: {
          customer: 0,
          insurance: 0,
        },
        unitIncludedKm: 0,
      },
      day: {
        quantity: 0,
        unitPrice: {
          customer: 0,
          insurance: 0,
        },
        unitIncludedKm: 0,
      },
      hour: {
        quantity: 0,
        unitPrice: {
          customer: 0,
          insurance: 0,
        },
        unitIncludedKm: 0,
      },
      km: {
        quantity: 0,
        unitPrice: {
          customer: 0,
          insurance: 0,
        },
        unitIncludedKm: 0,
      },
      cdw: {
        status: false,
        priceHalf: {
          customer: 0,
          insurance: 0,
        },
        priceFull: {
          customer: 0,
          insurance: 0,
        },
      },
      campaign: {
        status: false,
        key: null,
        start: null,
        end: null,
        includedKm: 0,
        day: {
          unit: null,
          price: 0,
          quantity: 0,
        },
        week: {
          unit: null,
          price: 0,
          quantity: 0,
        },
        month: {
          unit: null,
          price: 0,
          quantity: 0,
        },
      },
      addons: [],
      total: {
        // Contains only customer prices
        rent: 0,
        addons: 0,
        customer: 0, // rent + cdw + addons
        discount: 0, // Discount in kr
      },
    };

    const totalTime = msBetween(start, end);

    const includedTime = getIncludedTime(prefix, quantity, start, end);

    if (includedTime < 0) return; // There is no valid included time

    price.base.quantity = 1;
    price.base.unitIncludedKm = priceList.INKLUSIVE_KM;
    price.base.unitPrice = {
      customer: priceList.GRUNDPRIS * tax,
      insurance: priceList.GRUNDPRIS_FORSAK,
    };
    let remainingTime = Math.max(0, totalTime - includedTime);

    if (remainingTime > 0) {
      const remainingBase = Math.ceil(remainingTime / includedTime);
      const remainingDays = Math.ceil(remainingTime / hourToMs(24));
      const remainingHours = Math.ceil(remainingTime / hourToMs(1));
      const remainingKm = distance - priceList.INKLUSIVE_KM;
      const kmUnitPrice = priceList.KM_PRIS * tax;

      let remainingBasePrice = remainingBase * priceList.GRUNDPRIS * tax;
      // Add the price for km left
      const extraBaseKm = remainingKm - remainingBase * priceList.INKLUSIVE_KM;
      if (!priceList.FRI_KORSTRACKA && extraBaseKm > 0) {
        remainingBasePrice += extraBaseKm * kmUnitPrice;
      }
      let remainingDayPrice = Infinity;
      if (priceList.OVERDYGN_PRIS) {
        remainingDayPrice = remainingDays * priceList.OVERDYGN_PRIS * tax;
        // Add the price for km left
        const extraDayKm =
          remainingKm - remainingDays * priceList.INKLUSIVE_KM_OVERDYGN;
        if (!priceList.FRI_KORSTRACKA && extraDayKm > 0) {
          remainingDayPrice += extraDayKm * kmUnitPrice;
        }
      }
      let remainingHourPrice = Infinity;
      if (priceList.OVERTIMME_PRIS) {
        remainingHourPrice = remainingHours * priceList.OVERDYGN_PRIS * tax;
        // Add the price for km left
        const extraHourKm =
          remainingKm - remainingHours * priceList.INKLUSIVE_KM_OVERTIMME;
        if (!priceList.FRI_KORSTRACKA && extraHourKm > 0) {
          remainingHourPrice += extraHourKm * kmUnitPrice;
        }
      }
      if (prefix === "W") {
        if (remainingDayPrice === Infinity && remainingHourPrice === Infinity) {
          return; // There is no hour/day price to fill remainder of after week interval
        }
        remainingBasePrice = Infinity;
      }

      // Finds and uses the least expenive option for the remaining time
      if (
        remainingDayPrice < remainingBasePrice &&
        remainingDayPrice < remainingHourPrice
      ) {
        // Day price for remaing time is cheapest
        price.day.quantity = remainingDays;
        price.day.unitIncludedKm = priceList.INKLUSIVE_KM_OVERDYGN;
        price.day.unitPrice = {
          customer: priceList.OVERDYGN_PRIS * tax,
          insurance: priceList.OVERDYGN_PRIS_FORSAK,
        };
        remainingTime -= price.day.quantity * hourToMs(24);
      } else if (remainingHourPrice < remainingBasePrice) {
        // Hour price for remaining time is cheapest
        price.hour.quantity = remainingHours;
        price.hour.unitIncludedKm = priceList.INKLUSIVE_KM_OVERTIMME;
        price.hour.unitPrice = {
          customer: priceList.OVERTIMME_PRIS * tax,
          insurance: priceList.OVERTIMME_PRIS_FORSAK,
        };
      } else {
        // Base price for remaining time  is cheapest
        price.base.quantity = Math.max(1, Math.ceil(totalTime / includedTime));
        price.base.unitIncludedKm = priceList.INKLUSIVE_KM;
        price.base.unitPrice = {
          customer: priceList.GRUNDPRIS * tax,
          insurance: priceList.GRUNDPRIS_FORSAK,
        };
      }
    }

    /* Calculate price for distance */
    const includedKm =
      price.base.unitIncludedKm * price.base.quantity +
      price.day.unitIncludedKm * price.day.quantity +
      price.hour.unitIncludedKm * price.hour.quantity;

    if (!priceList.FRI_KORSTRACKA && distance > includedKm) {
      price.km.quantity = distance - includedKm;
      price.km.unitPrice = {
        customer: priceList.KM_PRIS * tax,
        insurance: priceList.KM_PRIS_FORSAK,
      };
    }

    //TODO: Priset per dygn blir billigare efter 30 dagar
    const days = daysBetween(start, end);

    if (days >= priceList.SKADERED_X_DYGN) {
      // Reservation period is longer than fixed period.
      const exceedingDays = days - priceList.SKADERED_X_DYGN;
      price.cdw.priceHalf.customer =
        priceList.KOSTNAD_X_DYGN + exceedingDays * priceList.SKADERED_EFTER_X;
      price.cdw.priceFull.customer =
        priceList.KOSTNAD_SKADEELEM_X_DYGN +
        exceedingDays * priceList.SKADEELEM_EFTER_X;
    } else {
      // Reservation period is shorter than fixed period
      price.cdw.priceHalf.customer = Math.min(
        priceList.SKADERED_DYGN * days,
        priceList.KOSTNAD_X_DYGN
      );
      price.cdw.priceFull.customer = Math.min(
        priceList.SKADEELEM_DYGN * days,
        priceList.KOSTNAD_SKADEELEM_X_DYGN
      );
    }

    price.total.rent =
      price.base.quantity * price.base.unitPrice.customer +
      price.day.quantity * price.day.unitPrice.customer +
      price.hour.quantity * price.hour.unitPrice.customer +
      price.km.quantity * price.km.unitPrice.customer;

    if (campaign) {
      price.campaign.key = campaign.key;
      price.campaign.kmPrice = campaign.KM_PRIS;
      price.campaign.start = campaign.start;
      price.campaign.end = campaign.end;

      if (campaign.day) {
        price.campaign.day.unit = campaign.day[0];
        price.campaign.day.price = campaign.day[1];
      }
      if (campaign.week) {
        price.campaign.week.unit = campaign.week[0];
        price.campaign.week.price = campaign.week[1];
      }
      if (campaign.month) {
        price.campaign.month.unit = campaign.month[0];
        price.campaign.month.price = campaign.month[1];
      }

      let campPrice = calculateCampaignPrice(
        start,
        end,
        campaign,
        priceList,
        price,
        distance,
        price.total.rent,
        priceLists,
        carGroup
      );

      if (price.total.rent > campPrice) {
        if (pengar.total.customer)
          price.total.rent = campPrice + pengar.total.customer;
        else price.total.rent = campPrice;
      } else {
        price.campaign.status = false;
      }
    }

    price.total.customer = Math.round(
      price.total.rent + price.total.addons - price.total.discount
    );

    prices.push(price);
  });

  if (prices.length === 0)
    return { total: { customer: 0 }, insurance: { price: 0 } };

  if (prices.length === 0) throw new RangeError("Inget pris kunde beräknas.");

  return prices.reduce((prev, curr) => {
    return curr.total.customer < prev.total.customer ? curr : prev;
  });
}

/**
 * Calculates the price for a campaign, if the campaign is valid for the reservation.
 * Returns Math.infinity if the campaign is not valid.
 * Returns the campaign price if the campaign is valid and the price is lower than the normal price.
 * @param {Date} start Start date of reservation
 * @param {Date} end End date of reservation
 * @param {Object} campaign Campaign object
 * @param {Object} priceList Price list object
 * @param {Object} price Price object
 * @param {Number} distance Distance in km
 * @param {Number} totalRent Total rent price
 * @returns {Number} The price for the campaign
 * @private
 * @ignore
 * @function
 * @name calculateCampaignPrice
 * @memberof module:price
 * @inner
 * @example
 * const price = calculateCampaignPrice(
 *  new Date("2020-01-01"),
 *  new Date("2020-01-02"),
 *  {
 *    key: "test",
 *    start: new Date("2020-01-01"),
 *    end: new Date("2020-01-02"),
 *    day: [1, 100],
 *    week: [7, 500],
 *    month: [30, 1000],
 *  },
 *  {}, // Price list
 *  {}, // Price object
 *  100,
 *  1000
 * );
 *
 */
function calculateCampaignPrice(
  start,
  end,
  campaign,
  priceList,
  price,
  distance,
  orgPrice,
  priceLists,
  carGroup
) {
  const newStart = new Date(start);
  const newEnd = new Date(end);
  const campaignEnd = new Date(campaign.end._seconds * 1000);
  const newCampaignEnd = campaignEnd;

  //Getting the normal cost for the reservation
  let normalDays = 0;
  let campaignDays = 0;

  //Using dayjs functions to calculate the days, weeks and months between the start and end date
  //const daysLeft = newCampaignEnd.diff(newStart, "day");
  const daysLeft = daysBetween(newStart, newCampaignEnd);
  //const rentDays = newEnd.diff(newStart, "day");
  const rentDays = daysBetween(newStart, newEnd);
  //const rentWeeks = Math.floor(rentDays / 7);
  //Setting a tempRentDays variable to the total days of the reservation
  let tempRentDays = rentDays;
  //This variable will contain all the costs of the booking
  let tempCampaignCost = 0;
  //Copying the object in order to avoid mutabillity issues
  campaignDays = rentDays;
  //Calculating if the campaign is valid for all days in the reservation
  if (daysLeft < rentDays) {
    normalDays = rentDays - daysLeft;
    campaignDays = daysLeft;
  }

  //Calculate the total amount of days that are in the campaign period
  //const totalDays = tempEnd.diff(newStart, "day");
  //This variable will label the type of campaign that it is ("day", "week" or "month")
  let type = undefined;

  tempRentDays = tempRentDays - normalDays;

  const rentWeeks = Math.floor(tempRentDays / 7);

  const rentMonths = Math.floor(tempRentDays / 30);

  if (daysLeft > 0) {
    //Calculates and adds the cost for the months that are in the campaign period
    if (campaign.month && rentMonths !== 0) {
      type = "month";
      price.campaign.month.quantity = rentMonths;
      tempCampaignCost += campaign.month[1] * rentMonths;
      price.campaign.status = true;
      tempRentDays -= 30 * rentMonths;
    }
    //Calculates and adds the cost for the weeks that are in the campaign period
    if (campaign.week && rentWeeks && rentWeeks > 0) {
      const tempWeeks = Math.floor(tempRentDays / 7);
      tempRentDays = tempRentDays % 7;
      price.campaign.status = true;
      if (!type) type = "week";
      price.campaign.week.quantity = tempWeeks;
      tempCampaignCost += campaign.week[1] * tempWeeks;
    }
    //Calculates and adds the cost for the days that are in the campaign period
    if (campaign.day && tempRentDays !== 0) {
      price.campaign.day.quantity = tempRentDays;
      if (!type) type = "day";
      tempCampaignCost += campaign.day[1] * tempRentDays;

      price.campaign.status = true;
    } else {
      //If the campaign is valid for more than one week the added days between will be cheaper
      if (campaign.week && campaignDays > 7) {
        if (!type) type = "day";
        price.campaign.status = true;
        price.campaign.day.quantity = campaign.week[1] / 7;
        tempCampaignCost += (campaign.week[1] / 7) * tempRentDays;
      }
    }
    if (!type) type = "day";

    //If the campaign is shorter than a week but more expensive than one campaign week the campaign week price will be returned
    if (rentDays < 7 && campaign.week && tempCampaignCost > campaign.week[1]) {
      price.campaign.status = true;
      tempCampaignCost = campaign.week[1];
    }

    //If TempCampaignCost is 0 and normalDays is 0 something is wrong
    if (tempCampaignCost === 0 && normalDays === 0) {
      tempCampaignCost = orgPrice;
    }

    if (tempCampaignCost === 0) {
      tempCampaignCost = orgPrice;
    }

    //Calculates extra km cost
    let totalKmPris = price.km.quantity * price.km.unitPrice.customer;

    //If the campaign has special km price and included km set the campaign cost instead
    if (!totalKmPris) totalKmPris = 0;

    //Calculates the total cost of the campaign
    let total =
      tempCampaignCost + price.hour.quantity * price.hour.unitPrice.customer;

    let includedKm = getCampaignIncludedKmPrice(
      campaign,
      rentWeeks,
      rentMonths,
      rentDays,
      type,
      total
    );

    price.campaign.includedKm = includedKm;
    const kmOver = Math.max(0, distance - includedKm);

    totalKmPris = kmOver * campaign.KM_PRIS;

    if (type === "week" && total > campaign.month[1]) {
      total =
        campaign.month[1] +
        totalKmPris +
        price.hour.quantity * price.hour.unitPrice.customer;
    } else if (type === "day" && campaign.week && total > campaign.week[1]) {
      total =
        campaign.week[1] +
        totalKmPris +
        price.hour.quantity * price.hour.unitPrice.customer;
    } else if (campaign.month && total > (rentMonths + 1) * campaign.month[1]) {
      total =
        (rentMonths + 1) * campaign.month[1] +
        price.hour.quantity * price.hour.unitPrice.customer +
        totalKmPris;
    } else {
      total =
        total +
        price.hour.quantity * price.hour.unitPrice.customer +
        totalKmPris;
    }

    return total;
  }
}

const getCampaignIncludedKmPrice = (
  campaign,
  rentWeeks,
  rentMonths,
  totalDays,
  type,
  totalPrice
) => {
  if (type === "month") {
    if (campaign.INKLUSIVE_KM_MONTH) {
      if (totalPrice > campaign.month[1] * (rentMonths + 1)) {
        return campaign.INKLUSIVE_KM_MONTH * (rentMonths + 1);
      }
      const daysOverMonth = totalDays - 30 * rentMonths;
      return (
        campaign.INKLUSIVE_KM_MONTH * rentMonths +
        campaign.INKLUSIVE_KM * daysOverMonth
      );
    } else {
      return totalDays * campaign.INKLUSIVE_KM;
    }
  }
  if (type === "week") {
    if (totalPrice > campaign.month[1] * 1 && campaign.INKLUSIVE_KM_MONTH) {
      return campaign.INKLUSIVE_KM_MONTH;
    }
    if (campaign.INKLUSIVE_KM_WEEK) {
      const daysOverWeek = totalDays - 7 * rentWeeks;
      return (
        campaign.INKLUSIVE_KM_WEEK * rentWeeks +
        campaign.INKLUSIVE_KM * daysOverWeek
      );
    } else {
      return totalDays * campaign.INKLUSIVE_KM;
    }
  }
  if (type === "day") {
    return totalDays * campaign.INKLUSIVE_KM;
  }
};
/**
 * Helper function that calculates how many milliseconds that is included for a specific time.
 *
 * @param {String} prefix Time unit. Possible values are: D (day), T (hour) and W (interval).
 * @param {Int} quantity Number of time units.
 *
 * @returns {Number} Included time in milliseconds (ms)
 */
function getIncludedTime(prefix, quantity, start, end) {
  let includedTime = -1;

  switch (prefix) {
    case "D":
      // Day
      includedTime = hourToMs(quantity * 24) + minutesToMs(35); // 24 hours in one day
      break;
    case "T":
      // Hour
      includedTime = hourToMs(quantity);
      break;
    case "W":
      // Interval
      // Calulates overlapping time of pricelist interval and booking time
      // Maximum days = days between. Ex: tuesday -> friday gives max 3 days(dygn)
      const intervalStartDay = parseInt(quantity.toString().charAt(0)) - 1; // Make 0-indexed
      const intervalEndDay = parseInt(quantity.toString().charAt(1)) - 1; // Make 0-indexed

      const reservationStartDay = start.getDay();

      // Gets next inteval end day date from start date
      const intervalEndOffset = (intervalEndDay - reservationStartDay + 7) % 7;
      let intervalEnd = new Date(start);
      intervalEnd.setDate(intervalEnd.getDate() + intervalEndOffset);
      intervalEnd.setHours(23, 59, 59, 999);
      const intervalStartOffset = (intervalEndDay - intervalStartDay + 7) % 7;
      let intervalStart = new Date(intervalEnd);
      intervalStart.setDate(intervalStart.getDate() - intervalStartOffset);
      intervalStart.setHours(0, 0, 0, 0);

      // start < intervalEnd by default
      // gets the overlapping time
      if (intervalStart < start) {
        if (end < intervalEnd) {
          includedTime = msBetween(start, end);
        } else {
          includedTime = msBetween(start, intervalEnd);
        }
      } else {
        if (intervalEnd < end) {
          includedTime = msBetween(intervalStart, intervalEnd);
        } else {
          if (intervalStart < end) {
            includedTime = msBetween(intervalStart, end);
          } else {
            includedTime = -1;
          }
        }
      }

      const msInDay = 1000 * 60 * 60 * 24;
      const daysAmount = (intervalEndDay - intervalStartDay + 7) % 7;
      includedTime =
        Math.min(msInDay * daysAmount, includedTime) + minutesToMs(35);
      break;

    default:
      break;
  }

  return includedTime;
}

/**
 * Helper function to convert hours to milliseconds (ms)
 *
 * @param {Number} hour
 *
 * @returns {Number} Milliseconds for given number of hours.
 */
function hourToMs(hour) {
  return hour * 60 * 60 * 1000;
}

/**
 * Helper function to convert minutes to milliseconds (ms)
 *
 * @param {Number} minutes
 *
 * @returns {Number} Milliseconds for given number of hours.
 */
function minutesToMs(minutes) {
  return minutes * 60 * 1000;
}
