import { Box, Container, Grid, useMediaQuery } from "@material-ui/core";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import redInfoIcon from "../../../assets/icons/info_red.svg";
import damageReductionIcon from "../../../assets/icons/damage_reduction.svg";
import plusIcon from "../../../assets/icons/plus.svg";
import { Text, TextBold } from "../../text/Text";
import { Context } from "../../../context/Store";
import FlexBoxRow from "../../flexBox/FlexBoxRow";
import Dropdown from "../../dropdown/Dropdown";
import RadioButton from "../../radioButton/RadioButton";
import StyledCheckbox from "../../styledCheckbox/StyledCheckbox";
import { daysBetween } from "../../../utils/date";
import Countdown from "../../countdown/Countdown";
import { Redirect, useHistory } from "react-router-dom";
import StyledPopover from "../../styledPopover/StyledPopover";
import FlexBoxColumn from "../../flexBox/FlexBoxColumn";
import { search } from "../../../utils/Search";
import { formatPrice } from "../../../utils/formating";
import StyledButton from "../../styledButton/StyledButton";
import DamageReductionInfo from "./DamageReductionInfo";
import { fetchAdditions, getAdditionCost } from "./bookingAdditionsUtil";
import { functions } from "../../../database/firebase";
import globeIcon from "../../../assets/icons/globe.svg";
import driverIcon from "../../../assets/icons/driver.svg";
import carIcon from "../../../assets/icons/cardIcons/car_type_grey.svg";
import BookingInfoCardVertical from "../../bookingInfoCardVertical/BookingInfoCardVertical";
import CustomAccordion from "../../customAccordion/CustomAccordion";
import { tax } from "../../../utils/price";

/**
 * Add booking addtions site and change booking
 * @author Erik Jonasson
 */
const BookingAdditions = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const isChangeBooking = pathname !== "/bokning/tillval";
  useEffect(() => {
    document.title = isChangeBooking ? "Ändra bokning" : "Bokningstillval";
  }, [isChangeBooking]);

  const {
    car,
    bookingTime,
    changeBooking,
    setBookingTime,
    setBookingAdditions,
    reservation,
    setPopUp,
    openHours,
  } = useContext(Context);

  const [haveAbroudPermit, setHaveAbroudPermit] = useState(false);
  const [haveTowbar, setHaveTowbar] = useState(false);
  const [abroadPermit, setAbroadPermit] = useState(null);
  const [towbarAddition, setTowbarAddition] = useState(null);
  const [haveExtraDrivers, setHaveExtraDrivers] = useState(false);
  const [extraDrivers, setExtraDrivers] = useState(1);
  const [priceInfo, setPriceInfo] = useState(0);
  const [damageReduction, setDamageReduction] = useState("0");
  const [errorMessage, setErrorMessage] = useState("");
  const [abroadPermits, setAbroadPermits] = useState(null);
  const [time, setTime] = useState(changeBooking?.bookingTime);
  const [loading, setLoading] = useState(false);
  const [additions, setAdditions] = useState(null);
  const updateAddition = (name, newDoc) => {
    setAdditions((prev) => {
      let state = [...prev];
      const index = state.findIndex((addition) => addition.NAMN === name);
      state[index] = { ...state[index], ...newDoc };
      return state;
    });
  };
  const currentCar = isChangeBooking ? changeBooking.car : car;
  const startDate = isChangeBooking ? time?.startDate : bookingTime?.startDate;
  const endDate = isChangeBooking ? time?.endDate : bookingTime?.endDate;
  const distance = isChangeBooking ? time?.distance : bookingTime?.distance;

  const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const priceRef = useRef();
  const [yBreakPoint, setYBreakPoint] = useState();

  // This function calculates Y
  const getPosition = () => {
    const y = priceRef?.current?.offsetTop;
    setYBreakPoint(y);
  };

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    fetchAdditions(startDate, endDate).then((res) => {
      if (res) {
        const { abroadAdditions, towbarAddition, additions } = res;
        setAbroadPermits(abroadAdditions);
        setAbroadPermit(abroadAdditions[0].value);
        setTowbarAddition(towbarAddition);
        setAdditions(additions);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Get the position of the ref in the beginning
  useEffect(() => {
    getPosition();
    // console.log(expandedView);
  }, [priceRef.current?.offsetTop]);
  // }, [priceRef.current?.offsetTop, expandedView]);

  // Re-calculate Y of the ref when the window is resized by the user
  useEffect(() => {
    window.addEventListener("resize", getPosition);

    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener("resize", getPosition);
    };
  }, []);

  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        getPosition();
      },
      { passive: true }
    );

    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener("scroll", () => {
        getPosition();
      });
    };
  }, []);

  if (!isChangeBooking && !(car && bookingTime)) {
    return <Redirect to="/" />;
  }
  if (isChangeBooking && !changeBooking) {
    return <Redirect to="/" />;
  }

  const days = bookingTime ? daysBetween(startDate, endDate) : 0;

  const cdw = {
    0: 0,
    1: priceInfo?.cdw?.priceHalf?.customer ?? 0,
  };

  const damageReductionInfo = [
    {
      info: "Basförsäkring",
      //info: "Självrisk: 10 000 kr",
      extraInfo: "Full självrisk i händelse av skada",
      price: "Inkluderat",
    },
    {
      info: "Självriskreducering",
      //info: "Självrisk: 5 000 kr",
      extraInfo: "Lägre självrisk i händelse av skada",
      price: formatPrice(cdw[1]),
    },
  ];
  const abroadCost = haveAbroudPermit ? getAdditionCost(abroadPermit, days) : 0;
  const damageReductionCost = cdw[damageReduction];
  const towbar = useMemo(() => {
    for (const carItem of car.availableCars) {
      if (carItem.towbar === true) {
        return true;
      }
    }
    return false;
  }, [car]);

  const getChosenAdditionsCost = () => {
    let sum = 0;
    additions
      ?.filter((addition) => addition.used)
      .forEach(
        (addition) =>
          (sum += getAdditionCost(addition, days) * addition?.number)
      );
    return sum;
  };

  const includedDistance =
    priceInfo?.base?.unitIncludedKm * priceInfo?.base?.quantity +
    priceInfo?.day?.unitIncludedKm * priceInfo?.day?.quantity +
    priceInfo?.hour?.unitIncludedKm * priceInfo?.hour?.quantity +
    priceInfo?.km?.quantity;

  const noTaxCost =
    abroadCost / tax +
    (priceInfo?.total?.customer ?? 0) / tax +
    (haveTowbar && towbarAddition ? getAdditionCost(towbarAddition, days) : 0) /
      tax +
    getChosenAdditionsCost() / tax;

  const additionsCost =
    damageReductionCost +
    abroadCost +
    (priceInfo?.total?.customer ?? 0) +
    (haveTowbar && towbarAddition ? getAdditionCost(towbarAddition, days) : 0) +
    getChosenAdditionsCost();

  const getAdditionList = () => {
    if (!additions) return [];
    let additionList = additions.filter((addition) => addition.used);
    if (haveAbroudPermit) {
      additionList = [...additionList, abroadPermit];
    }
    if (haveExtraDrivers) {
      additionList = [
        ...additionList,
        { NAMN: "Extra förare", number: extraDrivers },
      ];
    }
    if (haveTowbar) {
      additionList = [...additionList, towbarAddition];
    }
    additionList =
      additionList.map((addition) => {
        const number = addition?.number ?? 1;
        const numberText = number > 1 ? " - " + number : "";
        return {
          beskrivning: addition.NAMN,
          info: addition?.INFO,
          number: number,
          id: addition._id,
          MAX_KOSTNAD: addition?.MAX_KOSTNAD ?? 0,
          FAST_KOSTNAD: addition?.FAST_KOSTNAD ?? true,
          KOSTNAD: (addition?.KOSTNAD ?? 0) * number,
        };
      }) ?? 0;

    return additionList;
  };

  const additionList = {
    damageReduction,
    otherAdditions: additions?.filter((addition) => addition.used),
    extraDrivers: {
      name: "Extra förare",
      amount: haveExtraDrivers ? extraDrivers : 0,
    },
    towbar: {
      used: haveTowbar,
      addition: towbarAddition,
    },
    abroadPermit: {
      name: haveAbroudPermit ? abroadPermit : "",
    },
    cost: additionsCost,
  };

  const submit = () => {
    if (distance === 0 || distance === "" || distance === null) {
      setErrorMessage(
        "Du måste välja planerad körsträcka innan du kan gå vidare"
      );
      return;
    }
    setErrorMessage("");

    const additionList = getAdditionList();

    if (isChangeBooking) {
      submitChange(additionList);
    } else {
      sumbitNewBooking(additionList);
    }
  };

  const submitChange = async (additionList) => {
    if (loading) return;
    setLoading(true);
    setErrorMessage("");
    const bookingChange = functions.httpsCallable("booking-changeBooking");
    try {
      const bookingData = {
        carGroup: currentCar.priceGroup,
        startDate: Number(startDate),
        endDate: Number(endDate),
        distance: distance,
        damageReduction: Number(damageReduction),
        additions: additionList,
        abroad: haveAbroudPermit && abroadPermit ? abroadPermit.NAMN : "",
        bookingId: changeBooking.id,
      };
      await bookingChange(bookingData);
      history.push("/profil/mina-sidor");
    } catch (error) {
      switch (error.code) {
        default:
          if (error.message) {
            setErrorMessage(error.message);
          } else {
            setErrorMessage(
              "Något gick fel, testa igen och kontakta folkes biluthyrning om problemet kvarstår"
            );
          }
          break;
      }
    }
    setLoading(false);
  };

  const sumbitNewBooking = (additionList) => {
    const bookingAdditions = {
      damageReduction: Number(damageReduction),
      damageReductionCost: cdw[Number(damageReduction)],
      abroadPermit: haveAbroudPermit && abroadPermit ? abroadPermit.NAMN : "",
      additionList: additionList,
      cost: additionsCost,
      noTaxCost: noTaxCost,
      includedDistance: includedDistance,
    };
    setBookingAdditions(bookingAdditions);

    history.push("/bokning/slutfor");
  };

  const changeCar = () => {
    search(history, bookingTime);
  };

  const setBookingSetting = (settings) => {
    if (isChangeBooking) {
      setTime((prev) => ({ ...prev, ...settings }));
    } else {
      setBookingTime((prev) => ({ ...prev, ...settings }));
    }
  };

  const setStartDate = (date) => {
    setTime((prev) => ({ ...prev, startDate: date }));
  };
  const setEndDate = (date) => {
    setTime((prev) => ({ ...prev, endDate: date }));
  };

  return (
    <Container maxWidth={sm ? 0 : "md"} style={{ padding: sm && 0 }}>
      <Box
        display="flex"
        alignItems={["center", "flex-end"]}
        flexDirection={["column", "row"]}
        justifyContent="space-between"
        mt="30px"
        mb="40px"
        px={["24px", "24px", 0]}
      >
        <TextBold
          component="h1"
          fontSize={["24px", "24px", "35px"]}
          mb={["30px", "0px"]}
        >
          {"Bokning & tillval"}
        </TextBold>
        {!isChangeBooking && <Countdown endDate={reservation.until} />}
      </Box>

      <FlexBoxRow
        flexDirection={["column-reverse", "column-reverse", "row"]}
        alignItems={"start"}
        pt={[3, 3, 0]}
        style={{ padding: 0 }}
      >
        <FlexBoxColumn>
          <Grid container spacing={sm ? 0 : 3}>
            <Grid item xs={12}>
              <Box
                className={"card"}
                width={["auto", "auto", "auto"]}
                borderRight={["0", "0", "2px solid #EEE"]}
                borderBottom={"0"}
                textAlign="left"
                mt={[3, 3, 0]}
                style={{
                  padding: "30px",
                  borderRadius: sm && "5px 5px 0px 0px",
                }}
              >
                <TextBold
                  className="underline pointer"
                  display="flex"
                  alignItems="center"
                  mb={1}
                  onClick={() => setPopUp(DamageReductionInfo)}
                  fontSize={"18px"}
                >
                  <img
                    src={damageReductionIcon}
                    alt=""
                    style={{ margin: "0 5px", width: "23px" }}
                  />
                  Skadeförsäkring - Om olyckan är framme
                  <img
                    src={redInfoIcon}
                    alt=""
                    style={{ marginLeft: "10px" }}
                  />
                </TextBold>
                {currentCar.carClass !== "Släp" &&
                  damageReductionInfo.map((info, index) => (
                    <FlexBoxRow key={index} ml={[0, 0, "30px"]}>
                      <RadioButton
                        checked={damageReduction === index.toString()}
                        onChange={(e) => setDamageReduction(e.target.value)}
                        value={index}
                      />
                      <FlexBoxColumn alignItems="flex-start">
                        <Text>{info.info}</Text>
                        {/*<Text fontSize="14px">{info.extraInfo}</Text>*/}
                      </FlexBoxColumn>
                      <Text flexGrow="1" textAlign="right">
                        {info.price}
                      </Text>
                    </FlexBoxRow>
                  ))}
                {currentCar.carClass === "Släp" && (
                  <FlexBoxRow>
                    <Text ml="30px">Självriskreducering</Text>
                    <Text flexGrow="1" textAlign="right">
                      Inkluderat
                    </Text>
                  </FlexBoxRow>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                className={"card"}
                style={{
                  padding: sm ? "0px" : "30px",
                  borderRadius: 0,
                  borderWidth: sm && "1px 0 0 0",
                }}
                width={["auto", "auto", "auto"]}
                mt={[0, 0, 0]}
              >
                <TextBold
                  display="flex"
                  alignItems="center"
                  mb={2}
                  fontSize={"18px"}
                  style={{ padding: sm && "30px 30px 16px 30px" }}
                >
                  <img
                    src={plusIcon}
                    alt=""
                    style={{ margin: "0 5px", width: "20px" }}
                  />{" "}
                  Övriga tillval
                </TextBold>

                {abroadPermits && (
                  <FlexBoxRow
                    flexWrap="wrap"
                    px={["12px", "12px", "52px"]}
                    mb={2}
                    style={{
                      backgroundColor: "#F9F9F9",
                      borderRadius: "5px",
                      height: "80px",
                      marginLeft: sm && "12px",
                      marginRight: sm && "12px",
                    }}
                  >
                    <StyledCheckbox
                      checked={haveAbroudPermit}
                      onChange={(event) =>
                        setHaveAbroudPermit(event.target.checked)
                      }
                    />
                    <img
                      src={globeIcon}
                      style={{
                        width: sm ? "18px" : "30px",
                        paddingLeft: sm ? "8px" : "16px",
                        paddingRight: "8px",
                      }}
                    />

                    <StyledPopover
                      popover={
                        <FlexBoxColumn
                          alignItems="flex-start"
                          fontSize="16px"
                          maxWidth="500px"
                          padding={"8px"}
                        >
                          <TextBold>Utlandstillstånd</TextBold>
                          <Text>
                            Följande länder är tillåtna att resa till med våra
                            hyrbilar:
                            <br />
                            <br />
                            - Belgien
                            <br />
                            - Danmark
                            <br />
                            - Finland
                            <br />
                            - Frankrike
                            <br />
                            - Irland
                            <br />
                            - Liechtenstein
                            <br />
                            - Luxemburg
                            <br />
                            - Norge
                            <br />
                            - Nederländerna
                            <br />
                            - Schweiz
                            <br />
                            - Storbritannien
                            <br />
                            - Tyskland
                            <br />
                            - Österrike
                            <br />
                            <br />
                            <br />
                            Inom Norden tillkommer ingen avgift för
                            utlandstillstånd. Övriga länder tillkommer en avgift
                            på 300 kr per dygn. Maximalt 1200 kr per påbörjad
                            30-dygnsperiod.
                          </Text>
                        </FlexBoxColumn>
                      }
                    >
                      <Text>
                        Utlandstillägg{" "}
                        <img
                          src={redInfoIcon}
                          alt=""
                          style={{ marginLeft: "0px" }}
                        />
                      </Text>
                    </StyledPopover>
                    <FlexBoxRow
                      flexGrow="1"
                      justifyContent="flex-end"
                      flexWrap="wrap"
                    >
                      <Dropdown
                        medium
                        width={sm ? 80 : 104}
                        height={36}
                        borderRadius={5}
                        options={abroadPermits}
                        onChange={(value) => setAbroadPermit(value)}
                        disabled={!haveAbroudPermit}
                      />
                      <Text
                        width="6ch"
                        textAlign="right"
                        ml={["4px", "8px", "32px"]}
                        color={haveAbroudPermit ? "#000" : "#00000060"}
                      >
                        {formatPrice(
                          abroadPermit ? getAdditionCost(abroadPermit, days) : 0
                        )}
                      </Text>
                    </FlexBoxRow>
                  </FlexBoxRow>
                )}
                {currentCar.carClass !== "Släp" && (
                  <FlexBoxRow
                    mb={2}
                    px={["12px", "12px", "52px"]}
                    style={{
                      backgroundColor: "#F9F9F9",
                      borderRadius: "5px",
                      height: "80px",
                      marginLeft: sm && "12px",
                      marginRight: sm && "12px",
                    }}
                  >
                    <StyledCheckbox
                      checked={haveExtraDrivers}
                      onChange={(event) =>
                        setHaveExtraDrivers(event.target.checked)
                      }
                    />
                    <img
                      src={driverIcon}
                      style={{
                        width: sm ? "18px" : "30px",
                        paddingLeft: sm ? "8px" : "16px",
                        paddingRight: "8px",
                      }}
                    />
                    <Text>Extra förare</Text>
                    <FlexBoxRow flexGrow="1" justifyContent="flex-end">
                      <Dropdown
                        medium
                        width={57}
                        height={36}
                        borderRadius={5}
                        options={[
                          { value: 1, label: 1 },
                          { value: 2, label: 2 },
                        ]}
                        onChange={(value) => setExtraDrivers(value)}
                        disabled={!haveExtraDrivers}
                      />
                      <Text
                        width="6ch"
                        textAlign="right"
                        ml={["4px", "8px", "32px"]}
                        color={haveExtraDrivers ? "#000" : "#00000060"}
                      >
                        0 kr
                      </Text>
                    </FlexBoxRow>
                  </FlexBoxRow>
                )}
                {towbar && (
                  <FlexBoxRow
                    mb={2}
                    px={["12px", "12px", "52px"]}
                    style={{
                      backgroundColor: "#F9F9F9",
                      borderRadius: "5px",
                      height: "80px",
                      marginLeft: sm && "12px",
                      marginRight: sm && "12px",
                    }}
                  >
                    <StyledCheckbox
                      checked={haveTowbar}
                      onChange={(event) => setHaveTowbar(event.target.checked)}
                    />
                    <img
                      src={carIcon}
                      style={{
                        width: sm ? "18px" : "30px",
                        paddingLeft: sm ? "8px" : "16px",
                        paddingRight: "8px",
                      }}
                    />
                    <Text>Dragkrok</Text>
                    <FlexBoxColumn flexGrow="1" alignItems="flex-end">
                      <Text
                        width="6ch"
                        textAlign="right"
                        ml="32px"
                        mb={"4px"}
                        color={haveTowbar ? "#000" : "#00000060"}
                      >
                        {formatPrice(
                          towbarAddition
                            ? getAdditionCost(towbarAddition, days)
                            : 0
                        )}
                      </Text>
                      <Text
                        fontSize={14}
                        color={haveTowbar ? "#767676" : "#00000060"}
                        textAlign={"right"}
                      >
                        för {days} dygn
                      </Text>
                    </FlexBoxColumn>
                  </FlexBoxRow>
                )}

                {additions && (
                  <Box
                    mb={[0, 0, 2]}
                    px={["12px", "12px", "30px"]}
                    pt={"32px"}
                    pb={"16px"}
                    style={{ backgroundColor: "#F9F9F9", borderRadius: "5px" }}
                  >
                    <TextBold mb={3} textAlign={"start"}>
                      Bilbarnstol
                    </TextBold>
                    {additions.map((addition) => (
                      <FlexBoxColumn
                        alignItems={"start"}
                        mb={2}
                        py={"20px"}
                        px={["12px", "12px", "20px"]}
                        style={{
                          border: "2px solid #D8D7D7",
                          borderRadius: "5px",
                          height: "auto",
                          width: "auto",
                        }}
                      >
                        <TextBold
                          mb={0.5}
                          textAlign={"left"}
                          mx={["37px", "37px", "45px"]}
                        >
                          {addition.NAMN}
                        </TextBold>
                        <FlexBoxRow width={"100%"}>
                          <StyledCheckbox
                            checked={addition.used}
                            onChange={(event) =>
                              updateAddition(addition.NAMN, {
                                used: event.target.checked,
                              })
                            }
                          />
                          <Text fontSize={16} ml={["6px", "6px", 2]}>
                            {addition?.INFO ?? ""}
                          </Text>
                          <FlexBoxRow flexGrow="1" justifyContent="flex-end">
                            <Dropdown
                              medium
                              width={sm ? 45 : 57}
                              height={36}
                              borderRadius={5}
                              options={[
                                ...Array(addition.maxAmount).keys(),
                              ].map((x) => ({
                                value: x + 1,
                                label: x + 1,
                              }))}
                              onChange={(value) =>
                                updateAddition(addition.NAMN, { number: value })
                              }
                              disabled={!addition.used}
                            />
                            <FlexBoxColumn alignItems={"end"}>
                              <Text
                                width="6ch"
                                textAlign="right"
                                ml={["8px", "8px", "32px"]}
                                mb={"4px"}
                                color={addition.used ? "#000" : "#00000060"}
                              >
                                {formatPrice(
                                  addition.number *
                                    getAdditionCost(addition, days)
                                )}
                              </Text>
                              <Text
                                fontSize={14}
                                color={addition.used ? "#767676" : "#00000060"}
                                textAlign={"right"}
                              >
                                för {days} dygn
                              </Text>
                            </FlexBoxColumn>
                          </FlexBoxRow>
                        </FlexBoxRow>
                        <Box ml={["37px", "37px", "45px"]}>
                          <CustomAccordion title={"Läs mer"}>
                            <FlexBoxRow
                              justifyContent={"space-between"}
                              alignItems={"start"}
                            >
                              <Text textAlign={"left"} fontSize={14}>
                                {addition?.BESKRIVNING ?? ""}
                              </Text>
                              {/*addition?.MAX_KOSTNAD > 0 && (
                                <Text
                                  textAlign={"left"}
                                  fontSize={14}
                                  color={
                                    addition.used ? "#767676" : "#00000060"
                                  }
                                >
                                  Max {addition.MAX_KOSTNAD * 1.25} kr
                                </Text>
                              )*/}
                            </FlexBoxRow>
                          </CustomAccordion>
                        </Box>
                      </FlexBoxColumn>
                    ))}
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </FlexBoxColumn>
        <Box ref={priceRef}></Box>
        {sm && (
          <Box
            position="sticky"
            top="0px"
            left="0px"
            zIndex={2}
            justifyContent={"center"}
            width="100%"
            boxSizing={"border-box"}
            paddingLeft={scrollPosition > yBreakPoint - 95 ? "0px" : "24px"}
            paddingRight={scrollPosition > yBreakPoint - 95 ? "0px" : "24px"}
          >
            <Box
              bgcolor={"white"}
              border={"1.25px solid #F2F2F2"}
              borderRadius={5}
              pb={"20px"}
              boxSizing={"border-box"}
              paddingLeft={"24px"}
              paddingRight={"24px"}
            >
              <FlexBoxRow pt={"20px"} justifyContent="space-between">
                <TextBold>Hyreskostnad</TextBold>

                <TextBold color={currentCar.campaign ? "#EC2B34" : "#000000"}>
                  {formatPrice(additionsCost)}
                </TextBold>
              </FlexBoxRow>
            </Box>
          </Box>
        )}
        <Box
          ml={[0, 0, 3]}
          px={["24px", "24px", 0]}
          minWidth={["100%", "100%", "300px"]}
          style={{ boxSizing: "border-box" }}
        >
          <BookingInfoCardVertical
            car={currentCar}
            buttonText="Ändra"
            bookingTime={bookingTime}
            setPriceInfo={setPriceInfo}
            onSelect={isChangeBooking ? null : changeCar}
            startDate={startDate}
            endDate={endDate}
            priceInfo={priceInfo}
            openHours={openHours}
            setStartDate={isChangeBooking ? setStartDate : null}
            setEndDate={isChangeBooking ? setEndDate : null}
            distance={distance}
            changeDistance={(value) => setBookingSetting({ distance: value })}
            totalPrice={additionsCost}
            additionList={additionList}
            damageReduction={Number(damageReduction)}
          />
        </Box>
      </FlexBoxRow>
      <FlexBoxColumn
        width={"100%"}
        justifyContent={"center"}
        pt={4}
        pb={6}
        style={{ backgroundColor: sm && "#FFF" }}
      >
        <Text textAlign="start" color="red" my={"16px"}>
          {errorMessage}
        </Text>
        <StyledButton
          loading={loading}
          color="primary"
          onClick={submit}
          width={167}
        >
          <TextBold fontSize="18px">
            {isChangeBooking ? "Ändra bokning" : "Fortsätt"}
          </TextBold>
        </StyledButton>
      </FlexBoxColumn>
    </Container>
  );
};

export default BookingAdditions;
