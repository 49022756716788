import { Box, Container } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { Context } from "../../../context/Store";
import CarCardBooking from "../../carCardBooking/CarCardBooking";
import FlexBoxColumn from "../../flexBox/FlexBoxColumn";
import { Text, TextBold } from "../../text/Text";
import mapImage from "../../../assets/images/folkes_map_new.png";
import { Redirect } from "react-router-dom";

const BookingConfirmation = () => {
  useEffect(() => {
    document.title = "Bokningskonfirmation";
  }, []);

  const openMapInNewTab = () => {
    const newWindow = window.open(
      "https://www.google.com/maps/place/Sellerigatan+5,+224+78+Lund/data=!4m2!3m1!1s0x465397918f20fe73:0x5fa31113d7768368?sa=X&ved=2ahUKEwiVs-3En7WCAxVyRfEDHS_JA4QQ8gF6BAgPEAA",
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };
  const { car, bookingTime, bookingAdditions, customerInfo } =
    useContext(Context);

  if (!(car && bookingTime && bookingAdditions && customerInfo)) {
    return <Redirect to="/" />;
  }
  return (
    <Container maxWidth="md">
      <TextBold component="h1" fontSize={["24px", "24px", "35px"]} my="30px">
        Tack för din bokningsförfrågan!
      </TextBold>
      <Text mb="40px" fontSize={["18px", "20px", "20px"]}>
        Ett mail har skickats till {customerInfo.email}
      </Text>

      <Box
        display="flex"
        alignItems="stretch"
        justifyContent="center"
        flexDirection="row"
        flexWrap="wrap"
        mb={["60px", "90px"]}
      >
        <CarCardBooking
          car={car}
          bookingTime={bookingTime}
          bookingAdditions={bookingAdditions}
          mt="20px"
          mx="15px"
        />

        <FlexBoxColumn
          bgcolor={"#FFF"}
          border={"1.25px solid #F2F2F2"}
          borderRadius={5}
          alignItems="flex-start"
          width="220px"
          p="40px"
          fontSize="16px"
          mt="20px"
          mx="15px"
        >
          <Text fontSize="24px">Dina uppgifter</Text>
          <Box my="15px" borderTop="1.5px solid #E0E0E0" width="100%"></Box>
          <Text my="5px"> {customerInfo.firstName}</Text>
          <Text>{customerInfo.lastName}</Text>
          <Text my="5px">{customerInfo.email}</Text>
          <Text my="5px">{customerInfo.phoneNumber}</Text>
          <Text my="5px">{customerInfo.personNumber}</Text>
          <Text fontSize={["20px", "20px", "22px"]} mt="50px">
            Folkes biluthyrning AB
          </Text>
          <Box my="15px" borderTop="1.5px solid #E0E0E0" width="100%"></Box>
          <Text my="5px" color="#ec2b34" component="a" href="tel:+46-46-354140">
            046 354140
          </Text>
          <Text my="5px">Sellerigatan 5, 224 78, Lund</Text>
          <img
            style={{ width: "100%", height: "auto", cursor: "pointer" }}
            src={mapImage}
            alt="Karta med länk till Folkes biluthyrning på google maps"
            onClick={openMapInNewTab}
          />
        </FlexBoxColumn>
      </Box>
    </Container>
  );
};

export default BookingConfirmation;
