import firebase from "firebase/app";
import "firebase/functions";
import "firebase/storage";
import "firebase/firestore";
import "firebase/auth";

import config from "../config";

if (!firebase.apps.length) {
  /* if (config.env === "development") {
    console.log("Using Firebase Development");
    firebase.initializeApp(config.firebaseDev);
  } else {
    firebase.initializeApp(config.firebase);
  } */
  firebase.initializeApp(config.firebase);
} else {
  firebase.app(); // if already initialized, use that one
}

// const functions = firebase.functions();
const functions = firebase.app().functions("europe-west3");
const storage = firebase.storage();
const firestore = firebase.firestore();
const auth = firebase.auth();
auth.languageCode = "sv";
const timestamp = firebase.firestore.Timestamp;

if (config.env === "development") {
  console.log("Using Firebase Emulators");

  functions.useEmulator("localhost", 5001);
}

export { functions, storage, firestore, auth, timestamp };
