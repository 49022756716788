import { Box, Grid } from "@material-ui/core";
import React from "react";
import StyledButton from "../styledButton/StyledButton";
import StyledInput from "../styledInput/StyledInput";
import { functions } from "../../database/firebase";
import { Context } from "../../context/Store";
import { useEffect } from "react";
import { Text } from "../text/Text";

const InputField = ({ label, value, onChange, required }) => {
  return (
    <Grid item xs={12} sm={6}>
      <Text mb="10px">{label}</Text>
      <StyledInput
        value={value}
        onChange={onChange}
        bgcolor="#EEE"
        required={required}
      />
    </Grid>
  );
};

export default function UpdateInfoForm({ onSubmitForm }) {
  const [email, setEmail] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [postNumber, setPostNumber] = React.useState("");
  const [city, setCity] = React.useState("");

  const [errorMessage, setErrorMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const { customerInfo, setCustomerInfo } = React.useContext(Context);

  useEffect(() => {
    setEmail(customerInfo?.email);
    setPhoneNumber(customerInfo?.phoneNumber);
    setAddress(customerInfo?.address);
    setPostNumber(customerInfo?.postNumber);
    setCity(customerInfo?.city);
  }, [customerInfo]);

  const submit = async (event) => {
    event.preventDefault();
    if (loading) return;
    setErrorMessage("");
    setLoading(true);
    const changeCustomerInfo = functions.httpsCallable("customer-changeInfo");
    try {
      if (email === "") {
        setErrorMessage("Skriv in din email");
        setLoading(false);
      } else {
        await changeCustomerInfo({
          email,
          phoneNumber,
          address,
          postNumber,
          city,
        });

        setCustomerInfo((prev) => {
          const newInfo = {
            ...prev,
            email,
            phoneNumber,
            address,
            postNumber,
            city,
          };
          return newInfo;
        });
        onSubmitForm();
      }
    } catch (error) {
      setErrorMessage(
        "Något gick fel, testa igen och kontakta folkes biluthyrning om problemet kvarstår"
      );
    }
    setLoading(false);
  };

  return (
    <Box onSubmit={submit}>
      <Grid container spacing={2}>
        <InputField
          label="E-post *"
          value={email}
          onChange={setEmail}
          required
        />
        <InputField
          label="Telefonnummer"
          value={phoneNumber}
          onChange={setPhoneNumber}
          required
        />
        <InputField
          label="Adress"
          value={address}
          onChange={setAddress}
          required
        />
        <InputField
          label="Postnummer"
          value={postNumber}
          onChange={setPostNumber}
        />
        <InputField label="Stad" value={city} onChange={setCity} />
      </Grid>
      <Box mt={["30px", "30px", "55px"]}>
        <StyledButton
          loading={loading}
          type="submit"
          onClick={submit}
          fullWidth
        >
          Spara
        </StyledButton>
      </Box>
    </Box>
  );
}
