import { Box, Container, Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router";
import { Context } from "../../../context/Store";
import { functions } from "../../../database/firebase";
import StyledButton from "../../styledButton/StyledButton";
import StyledInput from "../../styledInput/StyledInput";
import { Text, TextBold } from "../../text/Text";

const InputField = ({ label, value, onChange, required }) => {
  return (
    <Grid item xs={12} sm={6}>
      <Text mb="10px">{label}</Text>
      <StyledInput
        value={value}
        onChange={onChange}
        bgcolor="#EEE"
        required={required}
      />
    </Grid>
  );
};

const UpdateProfileInfo = () => {
  useEffect(() => {
    document.title = "Ändra uppgifter";
  }, []);

  const { user, customerInfo, setCustomerInfo } = useContext(Context);
  const [email, setEmail] = useState(customerInfo?.email);
  const [phoneNumber, setPhoneNumber] = useState(customerInfo?.phoneNumber);
  const [address, setAddress] = useState(customerInfo?.address);
  const [postNumber, setPostNumber] = useState(customerInfo?.postNumber);
  const [city, setCity] = useState(customerInfo?.city);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const submit = async (event) => {
    event.preventDefault();
    if (loading) return;
    setErrorMessage("");
    setLoading(true);
    const changeCustomerInfo = functions.httpsCallable("customer-changeInfo");
    try {
      if (email === "") {
        setErrorMessage("Skriv in din email");
        setLoading(false);
      } else {
        await changeCustomerInfo({
          email,
          phoneNumber,
          address,
          postNumber,
          city,
        });

        setCustomerInfo((prev) => {
          const newInfo = {
            ...prev,
            phoneNumber,
            address,
            postNumber,
            city,
          };
          return newInfo;
        });
        history.push("/profil/mina-sidor");
      }
    } catch (error) {
      setErrorMessage(
        "Något gick fel, testa igen och kontakta folkes biluthyrning om problemet kvarstår"
      );
    }
    setLoading(false);
  };

  return (
    <Container>
      {!user ? (
        <Redirect to="/profil/logga-in" />
      ) : (
        <Box
          bgcolor={"#FFF"}
          border={"1.25px solid #F2F2F2"}
          borderRadius={5}
          padding={["20px", "40px"]}
          mx="auto"
          mt={["30px", "50px", "70px"]}
          mb={["60px", "90px"]}
          textAlign="left"
          maxWidth="480px"
          onSubmit={submit}
          component="form"
        >
          <Text fontSize="24px" mb="20px">
            Uppdatera kontouppgifter
          </Text>
          <Box mb="30px" borderBottom="1.5px solid #E0E0E0"></Box>
          <Grid container spacing={2}>
            <InputField
              label="E-post *"
              value={email}
              onChange={setEmail}
              required
            />
            <InputField
              label="Telefonnummer"
              value={phoneNumber}
              onChange={setPhoneNumber}
              required
            />
            <InputField
              label="Adress"
              value={address}
              onChange={setAddress}
              required
            />
            <InputField
              label="Postnummer"
              value={postNumber}
              onChange={setPostNumber}
            />
            <InputField label="Stad" value={city} onChange={setCity} />
          </Grid>
          <Box mt={["30px", "30px", "55px"]}>
            <StyledButton
              loading={loading}
              type="submit"
              onClick={submit}
              fullWidth
            >
              Spara
            </StyledButton>
          </Box>
          {errorMessage && (
            <TextBold mt="20px" fontSize="16px" color="red">
              {errorMessage}
            </TextBold>
          )}
        </Box>
      )}
    </Container>
  );
};

export default UpdateProfileInfo;
